/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 4-2-2018
 * Time: 23:00
 *
 */

angular.module('sihw.contextmenu', ['sihw.angular.config','sihw.sihwlog'])
//een interne value-service waarin we de menu's op id bewaren, met hun scope
    .value('_sihwcontextmenus', {})

    //de directive voor een contextmenu-definitie

    .directive('sihwcontextmenu', ['sihwAngularConfig','sihwlog', '_sihwcontextmenus',
        function (sihwAngularConfig,sihwlog, _sihwcontextmenus) {
            let log = sihwlog.logLevel(sihwAngularConfig.loglevel('sihwcontextmenu'));
            return {
                restrict: 'E',
                scope: true, //eigen subscope, geen isolate scope - zodat we bij de parent kunnen en destroy kunnen opvangen
                link: function ($scope, menu, $attrs) {
                    if (!$attrs.id) {
                        log.warn("sihwcontextmenu: Missende id bij sihwcontextmenu - kan niet gebruikt worden", menu);
                        return;
                    }
                    menu = $(menu);

                    //we maken een mini-service, die we bewaren en in de parentscope zetten:
                    let context = _sihwcontextmenus[$attrs.id] = $scope.$parent['$menu'] = {
                        $actief: true,
                        $el: menu,
                        $scope: $scope.$parent, //de parentscope, daarin komt de context-arg
                        $id: $attrs.id,
                        $isOpen: false,
                        open: function (left, top) {
                            if (!this.$actief) {
                                return; //al verwijderd
                            }

                            //is er een sihwcontextmenu-if?
                            if ($attrs.sihwcontextmenuIf && (! menu.find($attrs.sihwcontextmenuIf ).length)) {
                                log.debug('sihwcontextmenuIf faalt');
                                return; //dan laten we het openen zitten
                            }

                            this.$el.css({
                                top: top + 'px',
                                left: left + 'px'
                            }).addClass('open') //voor als er een bootstrap dropdown in zit
                                .show().focus();
                            //past het menu daar wel?
                            //viewport:
                            let $w = $(window);
                            let viewport =
                                {
                                    l: $w.scrollLeft(),
                                    t: $w.scrollTop(),
                                    w: $w.width(),
                                    h: $w.height()
                                };
                            viewport.r = viewport.l + viewport.w;
                            viewport.b = viewport.t + viewport.h;

                            //hoogte van het menu is lastiger, want abs:
                            //we gaan ervan uit dat de children dat niet zijn
                            let w = 0, h = 0;
                            this.$el.children().each(function () {
                                let child = $(this);
                                let cpos = child.position(); //tov het menu
                                let cr = cpos.left + child.width();
                                let cb = cpos.top + child.height();
                                w = Math.max(w, cr);
                                h = Math.max(h, cb);
                            });

                            let offset = this.$el.offset();
                            //te ver naar rechts?
                            if (offset.left + w > viewport.r && w < viewport.w) {
                                //linksaf
                                this.$el.css({left: (viewport.r - w) + 'px'});
                            }
                            if (offset.top + h > viewport.b && h < viewport.h) {
                                //naar boven
                                this.$el.css({top: (viewport.b - h) + 'px'});
                            }

                            this.$isOpen = true;

                            this.$el.one('blur', () => {this.close()});
                        },
                        close: function () {
                            log.debug('Menu close');
                            if (!(this.$actief && this.$isOpen)) {
                                return; //al verwijderd of niet open
                            }
                            log.debug('Close context');
                            this.$scope['$' + this.$id] = null;
                            this.$el.hide().removeClass('open'); //bootstrap dropdown
                            this.$isOpen = false;
                        }
                    };


                    $scope.$on('$destroy', deregistreer); //als scope weg, dan ook het menuitem weg.

                    //en preppen
                    menu.appendTo($('body')) //bovenop
                        .css({
                            position: 'absolute',
                            margin: 0,
                            zIndex: 9999
                        })
                        .hide()
                        .attr('tabindex', 0); //anders kunnen we hem geen focus geven

                    function deregistreer() {
                        let menu = _sihwcontextmenus[$attrs.id];
                        if (menu) {
                            menu.$actief = false;
                            delete _sihwcontextmenus[$attrs.id]
                        }
                    }
                }
            }
        }]).directive('sihwcontextmenuId', ['$rootScope', 'sihwAngularConfig', 'sihwlog', '_sihwcontextmenus', function ($rootScope, sihwAngularConfig, sihwlog, _sihwcontextmenus) {
    //koppelt een contextmenu aan dit item
    //attribute sihwcontext geeft een scope-expressie die in $scope.$<contextmenu-id> zit als het menu open is voor dit item
    //menu moet gezet zijn met sihwcontextmenu directive

    let log = sihwlog.logLevel(sihwAngularConfig.loglevel('sihwcontextmenu')); //dezelfde naam

    return {
        restrict: 'A',
        scope: false, //geen isolate scope, want die botst vaak met andere directives, we werken met attrs
        /*        scope: {
                    sihwcontextmenuId: '=',
                    sihwcontext: '='
                },*/
        link: function ($scope, $el, $attrs) {
            $el = $($el);

            let openMenu = false; //bevat het geopende menu-objedtt als wij hem hebben geopend (maar we moeten ook checken op $isOpen)

            $el.on('contextmenu', toonMenu)
                .on('$destroy', function () {
                    hideMenu();
                    $el.off('contextmenu');
                });

            function hideMenu($event) {
                if (openMenu && openMenu.$isOpen) {
                    openMenu.close();

                }
                openMenu = false;
            }

            function toonMenu(event) {
                log.debug('toonMenu',$attrs.sihwcontextmenuId);
                if (openMenu && openMenu.$isOpen) {
                    openMenu.close();
                    openMenu = false;
                }

                //niets te doen als er geen id is opgegeven, dan zijn we er gewoon niet
                //bijv in sihw-datatable zonder dat een gebruiker een contextmenu heeft opgegeven
                if (! $attrs.sihwcontextmenuId)
                {
                    return;
                }
                openMenu = $attrs.sihwcontextmenuId && _sihwcontextmenus[$attrs.sihwcontextmenuId];

                if (!(openMenu && openMenu.$actief)) {
                    log.warn(`sihwcontextmenu: Contextmenu met id ${$attrs.sihwcontextmenuId} niet gevonden of gedeactiveerd`);
                    //is het de bedoeling dat we het defaultmenu blocken?
                    if ('sihwcontextDefault' in $attrs) {
                        try {
                            return $scope.$eval($attrs.sihwcontextDefault);
                        }
                        catch (_e) {
                            log.warn(_e);
                            //val door naar default
                        }
                    }
                    return false;
                }

                //en de contextarg
                let context = null;
                try {
                    context = $scope.$eval($attrs.sihwcontext);
                }
                catch (_e) {
                    log.warn('sihwcontextmenu: probleem met evalueren sihwcontext', _e);
                }
                openMenu.$scope['$' + openMenu.$id] = context;
                openMenu.$scope.$apply(); //jsevent

                //open na de $apply, zodat deze een eventuele sihwcontextmenu-if jquery kan loslaten
                openMenu.open(event.pageX,event.pageY);

                return false; //niet bubbelen
            }
        }
    }
}]);

