/**
 * Spin in het Web Apeldoorn
 * Created by Jelmer on 12-3-2017.
 *
 * Gebruiker binnen een domein of een project.
 */

angular.module('dl.gebruiker', [
    'sihw.sihwlog',
    'dl.approuter',
    'dl.constants',
    'dl.api',
    'sihw.alert.service'
]).config(['approuterProvider', function (approuterProvider) {
    approuterProvider.state('gebruiker',
        {
            url: '/gebruiker/:gebruiker',
            templateUrl: 'states/gebruiker/gebruiker.html',
            controller: 'GebruikerController'
        })

}]).controller('GebruikerController', ['$scope', '$window', '$stateParams', '$timeout', '$uibModal', '$translate', 'approuter', 'sihwlog', 'sihwconfirm', 'sihwalert', 'api', '__', 'EMAIL_REGEX', 'FRONTEND_URL', function ($scope, $window, $stateParams, $timeout, $uibModal, $translate, approuter, sihwlog, sihwconfirm, sihwalert, api, __, EMAIL_REGEX, FRONTEND_URL) {
    var log = sihwlog.logLevel('debug');

    log.log('GebruikerController');
    var gebruiker = $stateParams.gebruiker;


    approuter.menutitel("VIEWTYPE.GEBRUIKER");

    $scope.FRONTEND_URL = FRONTEND_URL; //voor doorklikken

    //init de scope ui
    $scope.ui = {panels: {}};
    try {
        if (localStorage['userui_' + gebruiker]) {
            var newui = JSON.parse(localStorage['userui_' + gebruiker]);
            log.debug('NEW UI', newui);
            $scope.ui = newui;  //kopieer uit localstorage
        }
    } catch (_e) {
        //geen geluk, jammer dan
    }

    $scope.$watch('ui', function () {
            //sla op
            log.debug('UI gewijzigd', $scope.ui);
            try {
                localStorage['userui_' + gebruiker] = JSON.stringify($scope.ui);
            } catch (_e) {
                //nevermind
            }
        },
        true //watch op inhoud
    );


    initgebruiker();

    /**
     * Haal de gebruikersinfo (opnieuw) op
     */
    function initgebruiker() {
        api.gebruikerinfo(gebruiker)
            .then(function (info) {
                log.debug('gebruiker', info);
                $scope.gebruiker = info.user;
                $scope.projecten = info.projecten;
                $scope.ditbenik = (info.user.iduser === $scope.basis.userdata.iduser); //deze zelf
                if ($scope.ditbenik) {
                    log.warn('Dit ben ik');
                }
                angular.forEach(info.projecten, function (p) {
                    p.origadmin = p.admin; //even bewaren
                    p.origdocent = p.docent;
                    p.origactief = p.actief;
                    $scope.ui.panels[p.id] = $scope.ui.panels[p.id] || {
                        open: (info.projecten.length === 1),
                        order: '+titel' //we orderenen maar op 1 veld, geen array. En alleen af te schaffen door een ander veld te ordenen
                    }
                });
                approuter.menudomein(info.domein);
                //zijn we domeinadmin van dit domein?
                $scope.domeinadmin = ($scope.basis.userdata.caps.superadmin || ($scope.basis.userdata.caps.domeinadmin.indexOf(info.domein.id) !== -1));

                approuter.menutitel("VIEWTYPE.GEBRUIKER", info.user.username);

            })
            .catch(function (err) {
                log.warn(err);
                approuter.mainScreen(); //whatever
            })
    }

    $scope.changeOrder = function (project, veld) {
        //gewijzigd: we werken gewoon met één order veld, geen ordering binnen ordering

        var p = $scope.ui.panels[project.id];
        if (p.order === '+' + veld) {
            p.order = '-' + veld;
        } else {
            p.order = '+' + veld; //hoe dan ook
        }
    };

    $scope.setTemplate = function (model) {
        //zet het model op template true of false, gegeven het model
        api.setModelBool(model.idmodel, 'template', model.template).then(function (template) {
            model.template = model.origtemplate = template;
        }).catch(function () {
            model.template = model.origtemplate; //terugzetten
        });
    };

    $scope.setNorm = function (model) {
        //zet het model op norm true of false, gegeven het model
        api.setModelBool(model.idmodel, 'norm', model.norm).then(function (norm) {
            model.norm = model.orignorm = norm;
        }).catch(function () {
            model.norm = model.orignorm; //terugzetten
        });
    };

    /**
     * Zet de huidige gebruiker (in)actief in het gekozen project
     * @param project
     */
    $scope.setActief = function (project) {
        log.debug("Gebruiker " + gebruiker + " actief voor " + project.code + "?", project.actief);
        api.setProjectActief(gebruiker, project.id, project.actief).then(function (actief) {
            project.actief = project.origactief = actief;
        }).catch(function () {
            project.actief = project.origactief; //terugzetten
        });
    };

    $scope.setProjectadmin = function (project) {
        //zet de huidige gebruiker als projectadmin
        log.debug("Gebruiker " + gebruiker + " projectadmin voor " + project.code + "?", project.admin);
        api.setProjectadmin(gebruiker, project.id, project.admin).then(function (admin) {
            project.admin = project.origadmin = admin;
        }).catch(function () {
            project.admin = project.origadmin; //terugzetten
        });
    };

    $scope.setProjectdocent = function (project) {
        //zet de huidige gebruiker als docent of juist niet
        log.debug("Gebruiker " + gebruiker + " docent voor " + project.code + "?", project.docent);
        api.setDocent(gebruiker, project.id, project.docent).then(function (val) {
            project.docent = project.origdocent = val;
        }).catch(function () {
            project.docent = project.origdocent; //terugzetten
        });
    };

    /**
     * Open een wijzigdialoog voor de gebruiker. Toegang is in principe al geregeld door toegang tot de gebruiker en enabelen van knop.
     * Wat er precies mag, regelen we hier en in de dialoog
     */
    $scope.wijzigGebruiker = function () {
        $uibModal.open({
            animation: true,
            backdrop: 'static',
            windowClass: 'gebruiker_wijziggebruiker',
            scope: $scope, //scope wordt een kind van de onze, handig voor projectinfo
            templateUrl: '/states/gebruiker/wijziggebruiker.html',
            controller: [
                '$scope', '$uibModalInstance', '$translate', 'sihwalert', 'EMAIL_REGEX',
                function ($scope, $uibModalInstance, $translate, sihwalert, EMAIL_REGEX) {

                    //kopieer relevante info
                    $scope.model = {
                        iduser: $scope.gebruiker.iduser,
                        username: $scope.gebruiker.username,
                        displaynaam: $scope.gebruiker.displaynaam,
                        admin: $scope.gebruiker.domeinadmin,
                        superadmin: $scope.gebruiker.superadmin,
                        actief: $scope.gebruiker.actief,
                        materiaal_toegang: $scope.gebruiker.materiaal_toegang || '-',
                        wijzigwachtwoord: '-'
                    };
                    $scope.username = $scope.gebruiker.username;
                    log.debug($scope.model);
                    $scope.dialoog = $uibModalInstance;

                    //Mag de ingelogde gebruiker alles wijzigen? Alleen als hijzij super- of domeinadmin is, of projectadmin van alle projecten waar deze user in zit
                    log.debug('Ingelogd', $scope.basis.userdata);
                    $scope.admintoegang = $scope.domeinadmin || (!$scope.gebruiker.verborgenprojecten);

                    //wachtwoordopties:
                    var currentlang = $translate.preferredLanguage();
                    $scope.wachtwoordopties = [
                        {
                            label: $translate.instant('GEBRUIKER.WIJZIG.BEHOUDWACHTWOORD'),
                            lang: '-'
                        },
                        {
                            label: $translate.instant('GEBRUIKER.WIJZIG.WIJZIGWACHTWOORD', {lang: currentlang.toUpperCase()}),
                            lang: currentlang
                        }
                    ];
                    angular.forEach($translate.getAvailableLanguageKeys(), function (langkey) {
                        if (langkey !== currentlang) {
                            $scope.wachtwoordopties.push({
                                label: $translate.instant('GEBRUIKER.WIJZIG.WIJZIGWACHTWOORD', {lang: langkey.toUpperCase()}),
                                lang: langkey
                            });
                        }
                    });

                    $scope.opslaan = function () {
                        //we gooien het naar achteren, die valideert wel
                        api.wijziggebruiker($scope.model) //inclusief id
                            .then(function () {
                                function klaar() {
                                    initgebruiker();
                                    $uibModalInstance.close();
                                };
                                if ($scope.model.wijzigwachtwoord !== '-') {
                                    sihwalert('GEBRUIKER.WIJZIG.WACHTWOORDVERSTUURD', 'GEBRUIKER.WIJZIG.WACHTWOORDVERSTUURD_UITLEG', "BTN_CONFIRM")
                                        .then(klaar);
                                } else {
                                    klaar();
                                }

                            }).catch(function (err) {
                            $scope.error = err.code ? $translate.instant("GEBRUIKER.WIJZIG.ERROR." + err.code) : err.msg;
                        });
                    };

                }]
        }).result.then(function () {
        })
            .catch(function () { //noop
            });
    };

    /**
     * Verwijder de gebruiker uit het domein, met procten en modellen en al
     * @param user
     */
    $scope.verwijderGebruiker = function () {
        //feedback vragen
        log.debug($scope.gebruiker);
        sihwconfirm(
           __("DOMEIN.VERWIJDERGEBRUIKER.TITEL"),
           __($scope.gebruiker.nummodellen ? "DOMEIN.VERWIJDERGEBRUIKER.TEKSTMETMODELLEN" : "DOMEIN.VERWIJDERGEBRUIKER.TEKST", {
                naam: $scope.gebruiker.username,
                nummodellen: $scope.gebruiker.modellen
            }), __('BTN_JA'), __('BTN_NEE')
        ).then(function (ok) {
            if (!ok) {
                return; //geannuleerd
            }

            api.toast('DOMEIN.VERWIJDERGEBRUIKER.WORKING');
            api.verwijderGebruiker($scope.gebruiker.iduser).then(function () {
                $window.history.back();
                api.toast("DOMEIN.VERWIJDERGEBRUIKER.GELUKT");
            }).catch(function (err) {
                sihwalert("DOMEIN.VERWIJDERGEBRUIKER.ERROR.TITEL", err.code ? "DOMEIN.VERWIJDERGEBRUIKER.ERROR." + err.code : err.msg);
            });
        });
    };
}]);
