/**
 * Spin in het Web Apeldoorn
 * Created by Jelmer on 3-11-2016.
 *
 * Applicatiespecifieke laag om de spinjs2 service
 *
 * Geen directive, wel veel basisdata in $rootScope
 */

angular.module('dl.api', [
    'sihw.sihwlog',
    'SpinJS2',
    'sihw.localecodes',
    'sihw.wait'
])
    .factory('api', ['$rootScope', '$q', '$timeout', 'sihwlog', 'spinJS2', 'localecodes', 'sihwWait', function ($rootScope, $q, $timeout, sihwlog, spinJS2, localecodes, sihwWait) {
        var log = sihwlog.logLevel('debug');

        var userdata; //buiten de prop, zie getUserdata etc

        //basis wordt gevuld met alle data die we courant bij de hand hebben. In elk geval de beschikbare domeinen en projecten
        var basis = $rootScope.basis = {
            ingelogd: false,
            numAanmeldingen: 0 //batch voor aantal aanmeldingen

        }; //gebruiken we in de rootscope

        //appdata zijn zaken die niet userafhankelijk zijn, maar toch in de rootscope moeten
        let appdata = $rootScope.appdata = {
            getUrl: spinJS2.getUrl(), //appdata.getUrl kan dus voor een get-request
            landen: {}, //zie hieronder
            talen: {}
        };

        //we halen de land- en taalcodes op en sorteren ze
        //landenlijst
        localecodes.landen().then(landen => {
            //geef de landcodes maar dan voor de landen gesorteerd:
            let landcodes = Object.keys(landen);
            landcodes.sort((a, b) => landen[a].localeCompare(landen[b]));
            appdata.landen = {};
            for (let code of landcodes) {
                appdata.landen[code] = landen[code];
            }
        });
        //parallel de talen
        localecodes.talen(1).then(talen => {
            let taalcodes = Object.keys(talen);
            taalcodes.sort((a, b) => talen[a].localeCompare(talen[b]));
            appdata.talen = {};
            for (let code of taalcodes) {
                appdata.talen[code] = talen[code];
            }
        });

        var api = {
            init: function () {
                var $this = this;

                //SpinJS2 service event:
                $rootScope.$on('SpinJS2.reconnect', function () {
                    $this.isLoggedIn(); //check de inlogstate
                    //niets te doen
                });

                $rootScope.$on('api.notify.nieuweRegistratie', () => {
                    if (basis.userdata && basis.userdata.caps) {
                        this._aanmeldingenBijwerken();
                    }
                });

                $rootScope.$on('api.notify.admingewijzigd', () => {
                    return this._basisBijwerken();
                });

                //check of we zijn ingelogd enzo
                this.isLoggedIn();
            },

            /**
             * Test de verbinding
             * @returns {$q.defer.promise}
             */
            ping: function () {
                log.debug('Ping', spinJS2.getUrl());
                return spinJS2.ping().then(function () {
                    log.debug("Pong");
                });
            },

            /**
             * Check of we al zijn ingelogd in het backend, levert ook nieuwe authorisatie en userdata op
             * @returns {promise} Promise die resolvt met true/false of reject bij grote error
             */
            isLoggedIn: function () {
                if (!spinJS2.hasAuth()) {
                    //zeker niet ingelogd
                    return $q.resolve(false);
                }

                if (userdata && spinJS2.hasAuth()) {
                    //we geloven het wel
                    return $q.resolve(true);
                }

                var $this = this;
                if (this.__isLoggedInPromise) {
                    log.debug("isLoggedIn dubbel: we gebruiken de bestaande promise")
                    return this.__isLoggedInPromise; //is al bezig
                } else {
                    return this.__isLoggedInPromise = spinJS2.send('admin', 'relogin').then(
                        function (data) {
                            log.log('relogin gelukt', data);
                            $this._updateLogindata(data);
                            return true;
                        },
                        function (err) {
                            log.log('err bij relogin', err);
                            return false;
                        }).finally(function () {
                        delete $this.__isLoggedInPromise;
                    });
                }
            },

            /**
             * inloggen met ingevoerde gebruikersnaam en wachtwoord. Via de admin-controller, speciaal voor backoffice
             * @param {string} domein
             * @param {string} username
             * @param {string} password
             * @returns {promise} promise die resolvet met true/false of reject bij grote error (spinjs2 errors worden gewrapped in resolve(false))

             */
            login: function (domein, username, password) {
                var $this = this;
                $this.logout(); //uitloggen
                //spin v2
                var data = {
                    domein: domein,
                    username: username,
                    password: password
                };
                return spinJS2.send('admin', 'login', data).then(
                    function (data) {
                        //gelukt? -- we krijgen een webtoken en data
                        log.log('inloggen gelukt', data);
                        $this._updateLogindata(data);
                        return true;
                    },
                    function (err) {
                        log.log('err bij login', err);
                        return false;
                    });
            },

            /**
             * Verwerk de data van geslaagde login of relogin-actie
             * @param data
             * @private
             */
            _updateLogindata: function (data) {
                spinJS2.setAuth(data.authkey);  //de teruggegeven key gebruiken we voortaan
                basis.ingelogd = true;
                userdata = data.userdata;
                basis.userdata = this.getUserdata(); //kopie
                this._basisBijwerken();
            },

            /**
             * Regel het bijwerken van de basisdata: haal de domeinen e.d. op Los van de userdata, zodat het bij wijzigingen opnieuw kan
             * @private
             */
            _basisBijwerken: function () {
                //domeinen. Alleen voor superadmin

                if (!(basis.userdata && basis.userdata.caps)) {
                    return $q.reject(false);
                }
                //admin domeinen kan nu ook als geen superadmin. Krijg je gewoon minder terug
                return this.domeinen().then(
                    domeinen => {
                        //pas hier opschonen
                        basis.domeinen = {hash: {}, arr: []};
                        basis.domeinen.hash = domeinen;
                        angular.forEach(domeinen, function (domein) {
                            basis.domeinen.arr.push(domein);
                        });
                        basis.domeinen.arr.sort(function (a, b) {
                            return a.naam.localeCompare(b.naam)
                        });
                        log.debug('Basis is nu', basis);
                        return true;
                    }).then(() => {
                    //aantal aanvragen
                    return this._aanmeldingenBijwerken();
                });
            },

            /**
             * Verwijder de data rond een ingelogde user + auth. Geen interfaceacties
             */
            logout: function () {
                spinJS2.deleteAuth(); //weg met de auth
                $rootScope.basis = basis = {ingelogd: false}; //alles weg
                $rootScope.menu = {
                    titel: 'Dynalearn Admin'
                };
            },

            /**
             * Ruwe send
             * @param controller
             * @param actie
             * @param data
             */
            send: function (controller, actie, data) {
                return spinJS2.send(controller, actie, data);
            },

            /**
             * Breed zoeken, zie schermopbouw.js
             * @param zoekterm
             */
            zoeken: function (zoekterm) {
                log.debug(`api.zoeken`, zoekterm);
                return this.send('admin', 'zoek', {zoekterm: zoekterm}).then(function (res) {
                    log.debug(`result`, res);
                    return res.result;
                });
            },

            /**
             * Haal alle beschikbare domeinen op
             * @return {Promise}
             */
            domeinen: function () {
                return spinJS2.send('admin', 'domeinen').then(
                    function (data) {
                        return data.domeinen;
                    }
                );
            },

            /**
             * Haal informatie over een project op: id, beschrijving, aantal users en aantal projecten. Voor zover toegankelijk voor deze user
             * @param domeinid
             * @return {Promise<*>}
             */
            domeindata: function (domeinid) {
                return spinJS2.send('admin', 'domeindata', {id: domeinid}).then(
                    function (data) {
                        return data;
                    }
                );
            },

            /**
             * Haal de basisdata voor normcheck op: alle modellen en wat erbij hoort van een domein. Geeft false als het mislukt
             * Geen async, want ng-1 promisses met scope-apply
             * @param domeinid
             * @returns {Promise<any>}
             *
             */
            normcheckdata(domeinid) {
                return spinJS2.send('admin', 'normcheckdata', {domeinid: domeinid}).then(res => {
                    return res && res.result;
                }).catch(e => {
                    log.warn(e);
                    return false;
                });
            },

            /***
             * Helper bij normcheck: haal de data voor een lijst modellen op, voor zover dat mag
             * @param modelids
             */
            modelcontent(modelids) {
                return spinJS2.send('admin', 'modelcontent', {ids: modelids}).then(res => {
                    return res && res.result;
                }).catch(e => {
                    log.warn(e);
                    return false;
                });
            },
            /**
             * Maak een nieuw project aan in het gegeven domein.
             * @param domeinid
             * @param projectnaam
             * @param [beschrijving]
             * @returns Promise met projectid
             */
            nieuwproject: function (domeinid, projectnaam, beschrijving) {
                var $this = this;
                return spinJS2.send('admin', 'nieuwproject', {
                    domeinid: domeinid,
                    naam: projectnaam,
                    beschrijving: beschrijving
                }).then(function (res) {
                    return $this._basisBijwerken().then(function () {
                        return res.project;
                    });
                });
            },

            /**
             * Wijzig een project - toestemming wordt verondersteld
             * @param {object} projectdata inclusief id
             * @param {string|boolean } stuurmail Taalcode van de te versturen mail of false als geen mail
             */
            wijzigproject: function (projectdata, stuurmail) {
                let $this = this;
                //resultaat is gewoon resolve, of reject met errorcode
                return sihwWait(spinJS2.send('admin', 'wijzigproject', {
                    data: projectdata,
                    stuurmail: stuurmail
                })).then(function () {
                    return $this._basisBijwerken();
                });
            },

            /**
             * Migreer een project
             * @param {string} projectid
             * @param {string} nieuwdomein
             * @param {string} nieuwenaam
             * @param {boolean} checkVerwijderuser
             */
            migreerproject: function (projectid, nieuwdomein, nieuwenaam, checkVerwijderuser) {
                //resultaat is gewoon resolve, of reject met errorcode
                return sihwWait(spinJS2.send('admin', 'migreerproject', {
                    project: projectid,
                    nieuwdomein: nieuwdomein,
                    nieuwenaam: nieuwenaam,
                    checkVerwijderuser: checkVerwijderuser
                })); //basisbijwerken hoeft niet, want dat gaat wel per event
            },

            /**
             * Kopieer een project binnen een domein
             * @param {string} projectid
             * @param {string} nieuwenaam Nieuwe naam van het project
             * @param {string[]} modellen Id's van modellen die gekopieerd moeten worden
             * @param {string[]} userprojects userproject-id's van gebruikers die mee moeten
             * @param {string} [weesmodeleigenaar] Nodig als er modellen meegaan waarvan de eigenaar niet op de nieuwe plek is. Userproject-id
             * @param {string} [nieuwdomein] Superadmins mogen naar een ander domein kopieren. Dit is de id.
             * @returns {*}
             */
            kopieerproject: function (projectid, nieuwenaam, modellen, userprojects, weesmodeleigenaar, nieuwdomein) {
                return sihwWait(spinJS2.send('admin', 'kopieerproject', {
                    project: projectid,
                    naam: nieuwenaam,
                    modellen: modellen,
                    userprojects: userprojects,
                    weesmodeleigenaar: weesmodeleigenaar,
                    nieuwdomein: nieuwdomein
                })); //basisbijwerken hoeft niet, want dat gaat wel per event
            },

            /**
             * Eenvoudiger kopieerfunctionaliteit t.b.v. docenten (domeinadmins)
             * @param {number} projectid
             * @param {string} nieuwenaam
             * @param {boolean} maakcode Moet er meteen een inlogcode gemaakt worden?
             */
            kopieerproject_simpel(projectid, nieuwenaam, maakcode) {
                return sihwWait(spinJS2.send('admin', 'simpelkopieerproject', {
                    project: projectid,
                    naam: nieuwenaam,
                    code: maakcode
                })); //basisbijwerken hoeft niet, want dat gaat wel per event
            },

            verwijderProject: function (projectid) {
                var $this = this;
                return spinJS2.send('admin', 'verwijderproject', {
                    id: projectid
                }).then(function () {
                    return $this._basisBijwerken(); //bijwerken
                })
            },

            /**
             * Wijzig een gebruiker - toestemming wordt door backend gecheckt
             * @param {object} gebruikerdata inclusief id . Kan informatie over wijzigen van wachtwoord bevatten, zie gebruiker.js
             */

            wijziggebruiker: function (gebruikerdata) {
                //resultaat is gewoon resolve, of reject met errorcode
                return spinJS2.send('admin', 'wijziggebruiker', gebruikerdata);
            },

            /**
             * Verwijder een gebruiker uit het project, backend bepaalt of de gebruiker helemaal weg kan, Modellen gaan ook weg
             * @param userproject
             */
            gebruikerUitProject: function (userproject) {
                return spinJS2.send('admin', 'gebruikerUitProject', {
                    userproject: userproject
                });
            },


            /**
             * Sla wijzigingen in info over een domein op.
             * @param {string} domeinid De huidige id van het domein
             * @param {object} wijzigingen object met wijzigingen:
             *  @param {string} [wijzigingen.naam] De gewijzigde naam van het domein - alleen als superadmin
             *  @param {string} [wijzigingen.beschrijving] De gewijzigde beschrijving van het domein
             * @returns {Promise} resolvet met de nieuwe domeindata. Het backend check beveiliging
             */
            saveDomeindata: function (domeinid, wijzigingen) {
                var $this = this;
                var data = {
                    id: domeinid
                };
                if (wijzigingen.naam && userdata.caps.superadmin) {
                    data.naam = wijzigingen.naam;
                }

                //overige data
                for (let veld of ['beschrijving', 'organisatie', 'organisatietype', 'adres', 'postcode', 'plaats', 'land', 'telefoonnummer', 'url'])
                    if (veld in wijzigingen) {
                        data[veld] = wijzigingen[veld];
                    }
                return spinJS2.send('admin', 'savedomeindata', data).then(
                    function (result) {
                        return $this._basisBijwerken().then(function () {
                            return result; //als in domeindata
                        });
                    });
            },

            /**
             *
             * @param {object} data. Bevat naam, optioneel beschrijving, email van de admin, optioneel displaynaam en stuurgegevens taal
             * @return {Promise}
             */
            nieuwDomein: function (data) {
                var $this = this;
                //wel superadmin?
                if (!userdata.caps.superadmin) {
                    return $q.reject({
                        code: 'NO_AUTH',
                        msg: 'Geen toestemming',
                        _spinjsrequest: {
                            controller: 'frontend',
                            actie: 'niewDomein'
                        }
                    });
                }

                //resultaat is nieuwe id van nieuwe domein
                return spinJS2.send('admin', 'nieuwdomein', data).then(function (res) {
                    return $this._basisBijwerken().then(
                        function () {
                            return res.domein; //dat is de id
                        }
                    );
                });
            },

            verwijderGebruiker: function (userid) {
                //achterkant moet maar controleren of de ingelogde user admintoegang heeft
                return spinJS2.send('admin', 'verwijderGebruiker', {
                    id: userid
                });
            },

            verwijderDomein: function (domeinid) {
                //wel superadmin?
                if (!userdata.caps.superadmin) {
                    return $q.reject({
                        code: 'NO_AUTH',
                        msg: 'Geen toestemming',
                        _spinjsrequest: {
                            controller: 'frontend',
                            actie: 'verwijderdomein'
                        }
                    });
                }
                return spinJS2.send('admin', 'verwijderdomein', {id: domeinid});
            },

            /**
             * Haal alle gebruikers binen een bepaald domein op, inclusief informatie over aantal modellen
             * @param domeinid
             */
            domeingebruikers: function (domeinid) {
                //wel superadmin of domeinadmin?
                if (!(userdata.caps.superadmin || userdata.caps.domeinadmin.indexOf(domeinid) !== -1)) {
                    return $q.reject({
                        code: 'NO_AUTH',
                        msg: 'Geen toestemming',
                        _spinjsrequest: {
                            controller: 'frontend',
                            actie: 'domeingebruikers'
                        }
                    });
                }
                return spinJS2.send('admin', 'gebruikers', {domein: domeinid}).then(function (res) {
                    return res.result; //de array met gebruikers
                });
            },

            /**
             * Maak een nieuwe domeingebruiker (zonder project)
             * @param {string} domeinid
             * @param {Object} gebruiker
             * @param {string} emailtaal //taalcode voor bevestigingsemail
             */
            nieuweDomeingebruiker: function (domeinid, gebruiker, emailtaal) {
                if (!(userdata.caps.superadmin || userdata.caps.domeinadmin.indexOf(domeinid) !== -1)) {
                    return $q.reject({
                        code: 'NO_AUTH',
                        msg: 'Geen toestemming',
                        _spinjsrequest: {
                            controller: 'frontend',
                            actie: 'domeingebruikers'
                        }
                    });
                }
                return sihwWait(spinJS2.send('admin', 'nieuweDomeingebruiker', {
                    domeinid: domeinid,
                    gebruiker: gebruiker,
                    emailtaal: emailtaal
                })).then(function (res) {
                    return res.result; //info over de gebruiker
                });
            },

            /**
             * Haal alle users uit het domein van het gegeven project op, tbv gebruikerskeuze. ACL is minstens projectadmin, maar dat regelt het be verder (onze ui heeft dat al geregeld door de juiste state te openen).
             * @param projectid
             */
            projectGebruikersKeuze: function (projectid) {
                return spinJS2.send('admin', 'projectGebruikersKeuze', {project: projectid}).then(
                    function (res) {
                        return res.users; //de array met gebruikers
                    }
                );
            },

            /**
             * Uitgebreide info over een gebruiker, met domein en projecten
             * @param gebruikersid
             */
            gebruikerinfo: function (gebruikersid) {
                return spinJS2.send('admin', 'gebruikerinfo', {id: gebruikersid}).then(
                    function (data) {
                        return data.result;
                    }
                );
            },

            /**
             * Stel een gebruiker in als actief of niet voor een project. Dat bepaalt de mogelijkheid tot inloggen. Resultaat is de nieuwe instelling (bool), in promise.
             * @param gebruikersid
             * @param projectid
             * @param actief
             */
            setProjectActief: function (gebruikersid, projectid, actief) {
                return spinJS2.send('admin', 'setprojectactief', {
                    user: gebruikersid,
                    project: projectid,
                    actief: actief
                })
                    .then(function (res) {
                        return res.actief;
                    });
            },

            /**
             * Stel een gebruiker in als admin voor een project, of juist niet. Resultaat is de nieuwe instelling (bool)
             * @param gebruikersid
             * @param projectid
             * @param admin
             */
            setProjectadmin: function (gebruikersid, projectid, admin) {
                return spinJS2.send('admin', 'setprojectadmin', {
                    user: gebruikersid,
                    project: projectid,
                    projectadmin: admin
                })
                    .then(function (res) {
                        return res.admin;
                    });
            },
            /**
             * Stel een gebruiker in als docent voor een project, of juist niet. Resultaat is de nieuwe instelling (bool)
             * @param gebruikersid
             * @param projectid
             * @param val
             */
            setDocent: function (gebruikersid, projectid, val) {
                return spinJS2.send('admin', 'setdocent', {
                    user: gebruikersid,
                    project: projectid,
                    val: val
                })
                    .then(function (res) {
                        return res.val;
                    });
            },
            /**
             * Set een boolean flag in het backend voor een userproject
             * Resultaat is de nieuwe instelling (bool)
             * @param gebruikersid
             * @param projectid
             * @param {'meekijker'|'dashboard1'|'dashboard2'} flag
             * @param val
             */
            setFlag: function (gebruikersid, projectid, flag, val) {
                return spinJS2.send('admin', 'setUpFlag', {
                    user: gebruikersid,
                    project: projectid,
                    flag: flag,
                    val: val
                })
                    .then(function (res) {
                        return res.val;
                    });
            },


            /**
             * Voeg gebruikers toe aan het opgegeven project. Gebruikers kunnen bestaande users zijn, of nieuwe (hangt van id of email af)
             * @param {string} project Id van het project
             * @param {Array} gebruikers Per gebruiker evt .id, .username en (optioneel) .displaynaam
             * @param {string|boolean} stuurmail taalcode van de te versturen mail of false als geen mail
             *
             * @returns Promise(<Array>) met feedback op niet toegevoegde gebruikers: de gebriukersdata met .statuscode toegevoegd
             */
            projectgebruikers: function (project, gebruikers, stuurmail) {
                var requestdata = {
                    project: project,
                    gebruikers: gebruikers,
                    stuurmail: stuurmail
                };

                return sihwWait(spinJS2.send('admin', 'projectgebruikersToevoegen', requestdata)).then(function (data) {
                    return data.feedback;
                    //als alles ok dan is .ok true en is er eventueel een .statuscode
                    //als niet ok dan is .ok false met .statuscode
                });
            },

            /**
             * Haal alle voor deze user beschikbare projecten in een domein (of een array van domeinen) op, inclusief informatie over aantal users en modellen
             * @return {Promise}
             */
            projecten: function (domeinid) {
                log.debug('admin:projecten');
                return spinJS2.send('admin', 'projecten', {domeinid: domeinid}).then(
                    function (data) {
                        log.debug('admin:projecten terug');
                        return data.projecten;
                    }
                ).catch(function (err) {
                    log.error('admin:projecten error', err);
                });
            },

            /**
             * Haal informatie over een project op, inclusief alle modellen en users
             * @param projectid
             * @return {*}
             */
            projectdata: function (projectid) {
                return spinJS2.send('admin', 'projectdata', {id: projectid}).then(
                    function (res) {
                        return res.result;
                    }
                );
            },

            /**
             * Haal de data voor een actionlog van dit project op. Backend controleert toestemming
             * @param projectid
             */
            actionlog(projectid) {
                return spinJS2.send('admin', 'actionlogs', {projectid: projectid});
            },

            /**
             * Haal de data op voor het v3-dashboard
             * @deprecated Zie DataDashboard
             * @param projectid
             */
            dashboardv3data(projectid) {
                return spinJS2.send('admin', 'dashboardv3data', {projectid: projectid});
            },

            /**
             * DataDashboard. Geef gegeven een aantal projectids alle bijbehorende users en hun modellen terug. Alleen basisinfo
             * @param projectids
             */
            datadashboard_usersmodellen(projectids) {
                return spinJS2.send('admin', 'datadashboard_usersmodellen', {projectids: projectids}).then(res => res.res);
            },

            /**
             * Geef de data op het juiste niveau terug, al geagregeerd
             * @param niveau
             * @param ids
             * @param skipleeg
             * @param skipgeennorm
             * @param actionlog
             * @returns {*}
             */
            datadashboard_data(niveau, ids, skipleeg, skipgeennorm, actionlog) {
                return spinJS2.send('admin', 'datadashboard_data', {niveau: niveau, ids: ids, skipleeg: skipleeg, skipgeennorm: skipgeennorm, actionlog: actionlog});
            },



            /**
             * Zet een boolean bij een model uit of aan. Template of norm dus
             * @param modelid
             * @param boolnaam
             * @param boolwaarde
             */
            setModelBool: function (modelid, boolnaam, boolwaarde) {
                return spinJS2.send('admin', 'setmodelbool', {
                    model: modelid,
                    bool: boolnaam,
                    waarde: !!boolwaarde //gegarandeerd bool
                })
                    .then(function (res) {
                        return res.waarde;
                    });
            },

            /////////////////aanmeldingen //////////////
            /**
             * Werk het aantal aanmeldingen in de basisdata bij
             * @private
             */
            _aanmeldingenBijwerken: function () {
                if (!(basis.userdata && basis.userdata.caps)) {
                    return $q.reject(false); //niet ingelogd
                }
                if (basis.userdata.caps.superadmin) {
                    return spinJS2.send('admin', 'openregistraties').then(
                        aantal => {
                            basis.numAanmeldingen = aantal.aantal;
                            return true;
                        }
                    ).catch(e => {
                        log.error(e);
                        basis.numAanmeldingen = 0;
                        return true; //vrolijk verder
                    })
                } else {
                    return $q.resolve(true);
                }
            },

            /**
             * Geeft een overzicht van de aanmeldingen terug
             */
            aanmeldingen: function () {
                return spinJS2.send('admin', 'registraties').then(res => {
                    return res.registraties;
                }).catch(e => {
                    log.error(e);
                    return false;
                })
            },

            /**
             * Verwerk een een aanmelding. Afhandeling bevat nieuwe status, en typespecifieke zaken. Die sturen we dus allemaal mee
             * @param aanmelding_id
             * @param afhandeling
             */
            afhandelenAanmelding: function (aanmelding_id, afhandeling) {
                let data = angular.copy(afhandeling);
                data.id = aanmelding_id;
                return spinJS2.send('admin', 'afhandeling_registratie', data); //doorvallen met then en catch
            },

            //////////////////algemeen///////////////


            /**
             * Zijn we op dit moment verbonden met het backend?
             * @return {boolean}
             */
            connected: function () {
                return spinJS2.connected();
            }
            ,

            /**
             * Resolve een promise zodra de verbinding met het backend okee is
             * @return {*}
             */
            whenConnected: function () {
                return spinJS2.whenConnected();
            }
            ,

            /**
             * Return de userdata van de ingelogde gebruiker (we hebben ook $rootScope.userdata, die is beter)
             * @return {*}
             */
            getUserdata: function () {
                return userdata ? angular.extend({}, userdata) : false;
            }
            ,

            /**
             * Controleer of de user een gegeven domein mag beheren
             * @param domein
             * @return {boolean}
             */
            beheertDomein: function (domein) {
                return userdata && (
                    userdata.caps.superadmin
                    ||
                    (userdata.domeinadmin.indexOf(domein) !== -1)
                );
            },

            /**
             * Test even een slome response
             */
            sloomtest: function () {
                return sihwWait(spinJS2.send("admin", "sloomtest"));
            },
            //toast
            /**
             * Simpele toast: zet een text in de rootscope en toont de toast, heel simpel. Zie  mainscreen en less
             * @param translatekey
             * @param toastclass
             */
            toast: function (translatekey, toastclass) {
                if ($rootScope.toastdata && $rootScope.toastdata.timer) {
                    //oude timer weg
                    $timeout.cancel($rootScope.toastdata.timer);
                }
                $rootScope.toastdata = {
                    translatekey: translatekey,
                    toastclass: toastclass,
                    timer: $timeout(_ => {
                        $rootScope.toastdata = null
                    }, 15000)
                };

            },

            /**
             * Zorg de de ui wordt bijgewerkt na een async functie
             * @param [value]
             * @returns {*}
             */
            asyncreturn: function (value) {
                return $q((resolve) => {
                    $timeout(() => {
                        resolve(value);
                    }, 1);
                });
            }
        };
        api.init();
        return api;
    }]);
