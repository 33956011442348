/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 26-10-2016
 * Time: 16:01
 *
 * Splash-scherm. Stuurt gewoon door naar login of mainscherm. Is vooral voor als er geen backend is
 */

angular.module('dl.splash', [
    'dl.approuter',
    'sihw.sihwlog',
    'dl.api',
    'dl.login'
])
    .config(['approuterProvider',
        function (approuterProvider) {
            approuterProvider.state('splash', {
                url: '/',
                openbaar: true, //onze eigen openbaar vlag
                openbare_landing: true, //ga hierheen als iemand een verbonden zone probeert te bereiken
                templateUrl: 'states/splash/splash.html',
                controller: 'SplashController'
            })
        }]
    )
    .controller('SplashController', ['$scope', '$timeout', 'api', 'approuter', 'sihwlog', function ($scope, $timeout, api, approuter, sihwlog) {
        var log = sihwlog.logLevel('debug');
        log.log('SplashController');


        function retryBackend() {
            //zijn we goed ingelogd?
            api.isLoggedIn().then(function (ingelogd) {
                    if (ingelogd) {
                        log.log("Al succesvol ingelogd");
                        approuter.mainScreen();
                    }
                    else {
                        log.log("Nog niet ingelogd");
                        approuter.go('login');
                    }
                },
                function (err) {
                    log.error('Splash: kon niet dispatchen door fout', err);
                    $timeout(function () {
                        api.whenConnected().then(retryBackend());
                    }, 500);
                }
            );
        }

        //als we connected zijn kijken we waar we heen kunnen
        api.whenConnected().then(retryBackend);
    }]);