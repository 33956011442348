/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 23-4-2018
 * Time: 13:35
 *
 *
 */

'use strict';

angular.module('sihw.localecodes', [
    'sihw.angular.config',
    'sihw.sihwlog'
])
    .provider('localecodes', function provideLocalecodes() {
        let standaardlocale = 'nl'; //standaard
        let locale = false; //geen specifieke

        return {
            //PROVIDER

            /**
             * Zet de juiste locale. Hierna worden talen en landen opgehaald volgens deze locale, of de standaard
             * @param newlocale
             */
            setLocale(newlocale) {
                locale = newlocale;
                return this; //chaining
            },

            //SERVICE
            $get: ['$http', 'sihwAngularConfig', 'sihwlog',
                function ($http, sihwAngularConfig, sihwlog) {
                    let log = sihwlog.logLevel(sihwAngularConfig.loglevel('localecodes'));


                    /**
                     * Laad een Cldr json file gegeven de huidige of standaard locale
                     * @private
                     * @param json, type file, bijvoorbeeld 'languages' of 'territories'
                     */
                    function laadCldr(json) {
                        log.debug(`Localecodes: laad ${json} voor ${locale || standaardlocale}`);
                        return $http.get(`bower_components/cldr-localenames-modern/main/${locale || standaardlocale}/${json}.json`).catch(() => {
                            return $http.get(`bower_components/cldr-localenames-modern/main/${standaardlocale}/${json}.json`);
                        }).then(response => {

                            let data = response.data;
                            //nu is de data gevonden, via de gekozen locale of anders de standaardlocale
                            //de eerste key in data is de gevonden taal. Daarin zit localDisplayNames. De eerste key daarin is de data
                            let taaldata = data.main[Object.keys(data.main)[0]];
                            return taaldata.localeDisplayNames[Object.keys(taaldata.localeDisplayNames)[0]];
                        });
                        //of het is mislukt, dat kan ook
                    }

                    let service = {

                        //service kan ook ook locale setten
                        /**
                         * Zet de juiste locale. Hierna worden talen en landen opgehaald volgens deze locale, of de standaard
                         * @param newlocale
                         */
                        setLocale(newlocale) {
                            locale = newlocale;
                            return this; //chaining
                        },
                        /**
                         * Return een promise van een hash met landcodes - landen gegeven de locale
                         */
                        landen() {
                            return laadCldr('territories').then(landen => {
                                //we willen de numerieke codes eruit en ook de altnames
                                let format = /^[A-Z]{2,3}$/;
                                let opcode = {};
                                for (let code of Object.keys(landen).filter(code => format.test(code))) {
                                    opcode[code] = landen[code];
                                }
                                return opcode;
                            });
                        },

                        /**
                         * Return een promise van een hash met taalcodes - talen gegeven de locale
                         * @param {int} [level=1] als 1, dan worden de iso-639-1 talen teruggegeven (2-tekencodes): moderne talen. Als 2, dan alle lengtes van de taalcodes
                         * @param {boolean} [regio=false] regioextensies a la NL-be
                         */
                        talen(level, regio) {
                            return laadCldr('languages').then(talen => {
                                //we gaan matchen via een regex. Deze zorgt ook dat de -alt-... eruit vallen
                                let format;
                                level = level || 1;
                                if (level === 1) {
                                    format = "^[a-z]{2}"
                                }
                                else {
                                    format = "^[a-z]{2,}";
                                }
                                if (regio) {
                                    format += "(-[^\-]+)?";
                                }
                                format += '$';
                                format = new RegExp(format);
                                let opcode = {};
                                for (let code of Object.keys(talen).filter(code => format.test(code))) {
                                    if (code === 'und' || code === 'zxx') {
                                        continue; //"onbekende taal"
                                    }
                                    opcode[code] = talen[code];
                                }
                                return opcode;
                            });
                        }
                    };
                    return service;
                }]
        };
    });