'use strict';

// Declare app level module which depends on views, and components
angular.module('myApp', [
    'ngSanitize',
    'pascalprecht.translate', //vertaling ook globaal
    'tmh.dynamicLocale', //set locale op basis van taalcode
    'dl.localconfig', //algemene configuratie
    'dl.approuter', //de approuter
    'dl.states', //alle states
    'myApp.version',
    'myApp.schermopbouw',
    //applicatieglobaal beschikbaar
    'sihw.valuesfilter',
    'myApp.filters',
    'sihw.pickclass.directive',
    'sihw.pickattr.directive',
    'sihw.switch'
])
//algemene config vertaling en locale
    .config(['$translateProvider', 'tmhDynamicLocaleProvider', function ($translateProvider, tmhDynamicLocaleProvider) {
        $translateProvider.useStaticFilesLoader({
            prefix: 'locale/',
            suffix: '.json'
        }).useSanitizeValueStrategy('sanitizeParameters')
            .uniformLanguageTag('bcp47') //tags altijd nl-NL etc, hyphen en 2e deel upper
            //de beschikbare talen moet hier ook genoemd worden
            .registerAvailableLanguageKeys(['en', 'nl','de'], {
                'en_*': 'en',
                'nl_*': 'nl',
                'de-*': 'de',
                '*': 'en' //anders in het Engels
            })
            // .preferredLanguage('en')
            //fallbacklanguage gaat alleen over als er missende codes zijn in een taal (of de hele taal mist): het wijzigt niet de taalcode. Dat doet de algemene wildcard hierboven
            .fallbackLanguage(['en', 'nl'])
            .determinePreferredLanguage();
         console.log("Taal: ", $translateProvider.preferredLanguage());

        //regel de juiste locale op basis van de taal in translate
        //het pad
        tmhDynamicLocaleProvider.localeLocationPattern('/bower_components/angular-i18n/angular-locale_{{locale}}.js');
        //en de taal
        tmhDynamicLocaleProvider.defaultLocale($translateProvider.preferredLanguage());

    }])
    .run([function () {
    }]);
