/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 2-10-2017
 * Time: 15:40
 *
 * <setscope vars={var1: <expr1>, var2: <expr2>,..}>
 *     plaats de variabele var1 in de scope waarin deze directive staat, met als waarde expr1 etc
 *     vooral handig als dezelfde evaluatie vaak moet worden uigevoerd
 */

angular.module('sihw.setscope.directive',['sihw.angular.config','sihw.sihwlog'])
    .directive('setscope',['sihwAngularConfig','sihwlog',
        function(sihwAngularConfig, sihwlog) {
        return {
            restrict: 'E',
            scope: false, //de scope van de parent
            link: function($scope,el,attr)
            {
                var log = sihwlog.logLevel(sihwAngularConfig.loglevel('setscope'));
                $scope.$watch(attr.vars,function(newval) {
                    //we gaan alle vals even doen
                    angular.forEach(newval,function(value,name) {
                        $scope[name] = value;
                    })
                });
            }
        };
    }]);
