/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 12-7-2019
 * Time: 13:31
 *
 * Definitie van de elementstructuren. Voor gebruik in de main app (angular, zie element.service), maar ook in workers.
 * Gewrapt in _libs.Elements, want we hebben geen import / export-structuur
 */

if (!self._libs) {
    self._libs = {};
}

self._libs.Elements = (function () {

    //snelheid: cache
    let subtypecache = {}; //gevraagde type => {subtype => true of false}
    const elementdefs = {
        base: {
            isa: null,
            hasname: false,
            connecttoparent: false,
            deletable: true,
            hideInSimulate: false,
            showMenuOnCreate: true,
            isrelation: false,
            taphandler: false,
            edgeHandles: {}, //standaard niet
            nodename: false,
            nodechildren: false,
            noderelations: false,
            labelPosition: 'right' //positie vh label. Moet ook in style gezet worden, alleen voor plaatsing input
        },

        topelement: {
            isa: 'base',
            hasname: true,
            nodechildren: {},
            noderelations: {}
        },
        subelement: {
            isa: 'base',
            connecttoparent: true,//(default:true) moet er een lijn getekend worden tussen dit element en de geselecteerde (parent) node
            hasname: true, //(default: true) de meeste subelementen hebben een naam.
        },

        //topelements
        entity: {
            isa: 'topelement',
            nodename: 'ENTITY' //wordt nog vertaald
        },
        agent: {
            isa: 'topelement',
            nodename: 'AGENT'
        },
        assumption: {
            isa: 'topelement',
            nodename: 'ASSUMPTION'
        },

        //subelements
        quantity: {
            isa: 'subelement',
            nodename: 'QUANTITY'
        },

        attribute: {
            isa: 'subelement',
            nodename: 'ATTRIBUTE'
        },

        //quantity space
        quantity_space: {
            isa: 'subelement',
            nodename: false, //geen naam
            qstype: false,
            hasname: false
        },

        quantity_space_element: {
            isa: 'subelement',
            hasname: true,
            deletable: true, //in principe verwijderbaar, maar het komt precies, dus er is een callback. Zie controller
            showMenuOnCreate: true
        },

        quantity_space_interval: {
            isa: 'quantity_space_element',
            qstype: 'interval',
            nodename: 'INTERVAL' //wordt nog vertaald
        },

        quantity_space_point: {
            isa: 'quantity_space_element',
            qstype: 'point',
            nodename: 'POINT' //wordt nog vertaald
        },

        //we doen een isa voor value, zodat we in normmodellen value en dvalue samen kunnen tellen
        value: {
            isa: 'subelement'
        },
        quantity_value: {
            isa: 'value',
            hasname: false,
            showMenuOnCreate: false,
            hideInSimulate: true //weg bij simulatie
        },

        derivative: {
            isa: 'subelement',
            hasname: false,
            deletable: false, //we zien het als intern element
            showMenuOnCreate: false
        },

        derivative_element: {
            isa: 'subelement',
            hasname: false,
            deletable: false,
            showMenuOnCreate: false
        },

        derivative_plus: {
            isa: 'derivative_element',
            nodename: 'D_PLUS'
        },

        derivative_zero: {
            isa: 'derivative_element',
            nodename: 'D_ZERO'
        },

        derivative_min: {
            isa: 'derivative_element',
            nodename: 'D_MIN'
        },

        derivative_value: {
            isa: 'value',
            hasname: false,
            hideInSimulate: true,
            showMenuOnCreate: false
        },

        //exogeen-property
        //alle subtypen van q_exo zijn elkaar uitsluitend. Bij plaatsen van een element van dit type
        //zal de code alle andere elementen van een q_exo subtype verwijderen
        q_exo: {
            //abstract
            isa: 'subelement',
            hasname: false,
            showMenuOnCreate: false
        },

        q_exo_decr: {
            isa: 'q_exo'
        },
        q_exo_steady: {
            isa: 'q_exo'
        },
        q_exo_incr: {
            isa: 'q_exo'
        },
        q_exo_sin: {
            isa: 'q_exo'
        },
        q_exo_para_pos: {
            isa: 'q_exo'
        },
        q_exo_para_neg: {
            isa: 'q_exo'
        },
        q_exo_random: {
            isa: 'q_exo'
        },

        //allvalues is net iets anders, het bestaat naast de q_exo subtypen en is dus zelf geen subtype van q_exo
        quantity_allvalues: {
            isa: 'subelement',
            hasname: false,
            showMenuOnCreate: false
        },

        //relation
        relation: {
            isa: 'base',
            isrelation: true,//heeft twee parents
            hasname: false,
            possibleargs: false /*
				 - nested array: elke array bestaat uit twee nodetypes [[type,type]]
				 */
        },

        //relations
        configuration: {
            isa: 'relation',
            hasname: true,
            nodename: 'CONFIGURATION',
            labelPosition: 'center',
            possibleargs: [['entity', 'entity'], ['agent', 'agent'], ['entity', 'agent'], ['agent', 'entity']]
        },

        //ineq
        //overkoepelend is er vooral voor normmodel
        ineq: {
            isa: 'relation',
            hasname: false,
            hideInSimulate: true
        },

        //we doen quantity en derivative verder wel apart, en alle waarden ook
        q_ineq: {
            isa: 'ineq',
            possibleargs: [['quantity', 'quantity'], ['quantity', 'quantity_space_point'], ['quantity_space_point', 'quantity_space_point'], ['quantity', 'sub:q_calc'], ['quantity_space_point', 'sub:q_calc'], ['sub:q_calc', 'sub:q_calc']]
        },
        q_ineq_lt: {
            isa: 'q_ineq'
        },
        q_ineq_lte: {
            isa: 'q_ineq'
        },
        q_ineq_eq: {
            isa: 'q_ineq'
        },
        q_ineq_gte: {
            isa: 'q_ineq'
        },
        q_ineq_gt: {
            isa: 'q_ineq'
        },

        d_ineq: {
            isa: 'ineq',
            possibleargs: [['derivative', 'derivative'], ['derivative', 'derivative_zero'], ['derivative_zero', 'derivative_zero'], ['derivative', 'sub:d_calc'], ['derivative_zero', 'sub:d_calc'], ['sub:d_calc', 'sub:d_calc']]
        },
        d_ineq_lt: {
            isa: 'd_ineq'
        },
        d_ineq_lte: {
            isa: 'd_ineq'
        },
        d_ineq_eq: {
            isa: 'd_ineq'
        },
        d_ineq_gte: {
            isa: 'd_ineq'
        },
        d_ineq_gt: {
            isa: 'd_ineq'
        },

        //calc
        //we doen quantity en derivative apart
        //maar met een gezamenlijke parent voor de edgehandles, plaatjes zijn hetzelfde
        calc: {
            isa: 'relation',
            hasname: false,
            neverConditional: true, //nooit in een conditieset, wordt altijd zonder condtag opgeslagen
            hideInSimulate: false, //laten we staan, met de ineqs eraan
            edgeHandles: { //bij plus en min zijn de handles op dit moment gelijk
                //handles voor verbindingen met edges. key is combinatie van richting (to, dan is de parentnode to, from dan is het from) en van reltype (source,target)
                //value is een array met offset-objecten (x,y) t.o.v. de positie van de partent.
                to_source: [
                    {x: -33.0, y: 0},
                    {x: -33, y: -10},
                    {x: -33, y: 9}],
                to_target: [
                    {x: 1.9, y: 6.9},
                    {x: 0.9, y: -10.3}
                ],
                from_source: [ //resultaat van de calc wordt gebruikt in een relatie met deze als eerste
                    {x: 30, y: 6.5},
                    {x: 30, y: -7},
                    {x: 34.5, y: 0.3}
                ],
                from_target: [ //resultaat van de calc wordt gebruikt in een relatie met deze als tweede
                    {x: 30, y: 6.5},
                    {x: 30, y: -7},
                    {x: 34.5, y: 0.3}
                ]
            }
        },
        q_calc: {
            isa: 'calc',
            possibleargs: [['quantity', 'quantity'], ['quantity', 'quantity_space_point'], ['quantity_space_point', 'quantity_space_point'], ['quantity', 'sub:q_calc'], ['quantity_space_point', 'sub:q_calc'], ['sub:q_calc', 'sub:q_calc'],]
        },
        q_calc_plus: {
            isa: 'q_calc'
        },
        q_calc_min: {
            isa: 'q_calc'
        },
        q_calc_mult: {
            isa: 'q_calc'
        },
        q_calc_div: {
            isa: 'q_calc'
        },

        //zo ook voor afgeleide, kan alleen bij afgeleiden
        d_calc: {
            isa: 'calc',
            possibleargs: [['derivative', 'derivative'], ['derivative', 'sub:d_calc'], ['sub:d_calc', 'sub:d_calc']]
        },
        d_calc_plus: {
            isa: 'd_calc'
        },
        d_calc_min: {
            isa: 'd_calc'
        },
        d_calc_mult: {
            isa: 'd_calc'
        },
        d_calc_div: {
            isa: 'd_calc'
        },

        //correspondentie
        correspondence: {
            //abstract
            isa: 'relation'
        },

        //tusenvormen voor de menu's op het canvas
        correspondence_qs: {
            isa: 'correspondence'
        },
        //we schrijven de verschillende correspondenties helemaal uit, een flinke rij
        correspondence_qs_normal: {
            isa: 'correspondence_qs',
            possibleargs: [['quantity_space', 'quantity_space']]
        },
        correspondence_qs_reverse: {
            isa: 'correspondence_qs',
            possibleargs: [['quantity_space', 'quantity_space']]
        },
        correspondence_qs_directed: {
            isa: 'correspondence_qs',
            possibleargs: [['quantity_space', 'quantity_space']]
        },
        correspondence_qs_directed_reverse: {
            isa: 'correspondence_qs',
            possibleargs: [['quantity_space', 'quantity_space']]
        },

        correspondence_dqs: {
            isa: 'correspondence'
        },
        correspondence_dqs_normal: {
            isa: 'correspondence_dqs',
            possibleargs: [['derivative', 'derivative']]
        },
        correspondence_dqs_reverse: {
            isa: 'correspondence_dqs',
            possibleargs: [['derivative', 'derivative']]
        },
        correspondence_dqs_directed: {
            isa: 'correspondence_dqs',
            possibleargs: [['derivative', 'derivative']]
        },
        correspondence_dqs_directed_reverse: {
            isa: 'correspondence_dqs',
            possibleargs: [['derivative', 'derivative']]
        },

        correspondence_qv: {
            isa: 'correspondence'
        },
        correspondence_qv_normal: {
            isa: 'correspondence_qv',
            possibleargs: [['sub:quantity_space_element', 'sub:quantity_space_element']]
        },
        correspondence_qv_directed: {
            isa: 'correspondence_qv',
            possibleargs: [['sub:quantity_space_element', 'sub:quantity_space_element']]
        },

        correspondence_dv: {
            isa: 'correspondence'
        },
        correspondence_dv_normal: {
            isa: 'correspondence_dv',
            possibleargs: [['sub:derivative_element', 'sub:derivative_element']]
        },
        correspondence_dv_directed: {
            isa: 'correspondence_dv',
            possibleargs: [['sub:derivative_element', 'sub:derivative_element']]
        },

        causal: {
            isa: 'relation',
            possibleargs: [['quantity', 'quantity']]
        },

        //tussenstap voor normmodelteller
        influence: {
            isa: 'causal'
        },

        influence_positive: {
            isa: 'influence'
        }
        ,
        influence_negative: {
            isa: 'influence'
        }
        ,

        proportionality: {
            isa: 'causal'
        },

        proportionality_positive: {
            isa: 'proportionality'
        }
        ,
        proportionality_negative: {
            isa: 'proportionality'
        },
        proportionality_multiply: {
            isa: 'proportionality'
        },
        proportionality_divide: {
            isa: 'proportionality'
        },

        ////Elementen zonder betekenis die om technische redenen toch worden opgeslagen in het model

        //het speciale geen element-type voor nodes die wel worden opgeslagen, maar geen element zijn (edgeHandle bijvoorbeeld)
        noElement: {
            isa: 'base',
            hasname: false,
            deletable: false,
            hideInSimulate: false, //moet worden geregeld door code
            showMenuOnCreate: false
        },

        edgeHandle: {
            isa: 'noElement'
        }
    };

    //bouw een boom met children
    for (let key of Object.keys(elementdefs)) {
        let def = elementdefs[key];
        if (!def.children) {
            def.children = []; //alvast toevoegen
        }
        if (!def.isa) {
            continue;
        }
        let parent = elementdefs[def.isa];
        if (!parent.children) {
            parent.children = [];
        }
        //en deze dus, alleen de naam
        parent.children.push(key);
    }

    function _baseMerge(nodetype) {
        let source = elementdefs[nodetype];
        if (!source) {
            //	console.warn('Elements._baseMerge: geen informatie over nodetype ' + nodetype + '. We kunnen geen base-properties toevoegen');
            return {};
        }

        let parent;
        if (source.isa) {
            parent = _baseMerge(source.isa); //eerst verder omhoog
        } else {
            parent = {};
        }
        return Object.assign({}, parent, source); //ook source klonen
    }

    return {
        /**
         * Geeft de definitie van een nodetype terug. Zonder vertaling!
         * @param nodetype
         */
        getDefinition: function (nodetype) {
            //we gaan mergen
            let r = _baseMerge(nodetype);
            r.nodetype = nodetype; //handig
            /*
                        if (r.nodename) {
                            r.nodename = $translate.instant(r.nodename); //sync! Maar gaat wel goed want ruim na laden https://github.com/angular-translate/angular-translate/issues/473

                        }*/
            return r;
        },

        /**
         * Returnt true als type in de hierarchie van parenttype valt
         * @param typeOrDef
         * @param parenttype
         * @return {boolean}
         */
        isSubtypeOf: function (typeOrDef, parenttype) {
            typeOrDef = (typeof typeOrDef == "object") ? typeOrDef.nodetype : typeOrDef;
            let res = false;
            if (typeOrDef == parenttype) {
                return true; //niet eens cachen, want dit is sneller
            }
            //gecached?
            let typecache = subtypecache[typeOrDef];
            if (!typecache) {
                typecache = {};
            }
            if (typecache[parenttype]) {
                return typecache[parenttype]; //we weten het al
            }
            let def = elementdefs[typeOrDef]; //heeft een eigen isa

            if (def && def.isa) {
                res = this.isSubtypeOf(def.isa, parenttype);
            }
            //toevoegen aan cache
            typecache[parenttype] = res;
            return res;
        },

        /**
         * Geef alle subtypen van een type terug in een array
         * @param typeOrDef
         */
        alleSubs: function (typeOrDef) {
            let self = this;
            let subs = [];
            let def = (typeof typeOrDef == "object") ? typeOrDef : elementdefs[typeOrDef];
            if (!def.children) {
                return []; //niets te doen
            }
            for (let child of def.children) {
                subs.push(child);
                Array.prototype.push.apply(subs, self.alleSubs(child)); //recursief
            }
            return subs;
        },

        alleUps: function (typeOrDef) {
            let def = (typeof typeOrDef == "object") ? typeOrDef : elementdefs[typeOrDef];
            let ups = [];
            while (def && def.isa) {
                ups.push(def.isa);
                def = elementdefs[def.isa];
            }
            return ups;
        },

        //wrappers:

        /**
         * True als het opgegeven type (of def) een topelement is
         * @param typeOrDef
         * @returns {boolean}
         */
        isTopelement: function (typeOrDef) {
            return this.isSubtypeOf(typeOrDef, 'topelement');
        },

        /**
         * True als het opgegeven type (of def) een subelement is
         * @param typeOrDef
         * @returns {boolean}
         */
        isSubelement: function (typeOrDef) {
            return this.isSubtypeOf(typeOrDef, 'subelement');
        },

        /**
         * True als het opgegeven type (of een hele definitie) een relatie is
         * @param typeOrDef
         * @returns {*}
         */
        isRelation: function (typeOrDef) {
            return this.isSubtypeOf(typeOrDef, 'relation');
        }
    };
})();
