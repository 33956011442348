/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 8-9-2017
 * Time: 10:01
 *
 * Verschillende filters
 * value | moment:<translationkey met format>
 */
angular.module('myApp.filters', ['pascalprecht.translate'])
    //value | moment:<translationkey met format>
    .filter('moment', ['$translate', function ($translate) {
        return function (str, format) {
            if (str === null || str === undefined) {
                return str; //gewoon terug
            }

            return moment(str).format(format ? $translate.instant(format) : "");
        }
    }])
//value | translate_or:<waarde> vertaalt het value of geeft waarde terug
    //we willen het synchroon, dus doen geen $translate.catch, maar checken of return gelijk is aan translationkey
    .filter('translate_or',['$translate', function ($translate) {
        return function (str, orwaarde) {
            let res = $translate.instant(str);
            return res === str ? orwaarde : res;
    }}]);
