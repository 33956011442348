/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 5-3-2018
 * Time: 12:32
 *
 */

angular.module('sihw.cache', ['sihw.angular.config', 'sihw.sihwlog', 'sihw.thenloops'])
    .factory('sihwcache', ['$q', 'sihwAngularConfig', 'sihwlog', 'thenEach', function ($q, sihwAngularConfig, sihwlog, thenEach) {
        let log = sihwlog.logLevel(sihwAngularConfig.loglevel('sihwcache'));
        log.debug('sihwcache startup');

        //sessionstorage, just in case. Voor opslaan cacheCryptkey
        let session = sessionStorage || {}; //#just in case
        /**
         * Helperclass voor het locken van de cache via een semafoor
         */
        class SihwCacheLock {
            constructor() {
                this._lockcount = 0;
                this._unlockDefer = null;
            }

            get locks() {
                return this._lockcount;
            }

            /**
             * Lock de cache voor de duur van de functie
             * aanroep: instance.lock(() => {....}[, locknaam]);
             * returnt een promise van het resultaat van fn
             * @param fn
             * @param [lognaam] naam tbv loggen
             */
            lock(fn, lognaam) {
                lognaam = lognaam || "lock";
                if (!this._lockcount) {
                    //nieuwe lock
                    log.debug(`START LOCK ${lognaam}`);
                    this._unlockDefer = $q.defer();
                }
                this._lockcount++;
                //run de functie en return het resultaat als promise. Finally returnt het resultaat van resolve(fn()), inclusief rejection
                log.debug(`Run locked functie ${lognaam}`);
                return $q.resolve(fn()).finally(() => {
                    log.debug(`Klaar met locked functie ${lognaam}`);
                    this._lockcount--;
                    if (this._lockcount === 0) {
                        //unlocken
                        log.debug(`CLEAR LOCK ${lognaam}`);
                        this._unlockDefer.resolve();
                    }
                });
            }

            /**
             * Return een promise die resolvt op het moment dat de lock weg is
             */
            get unlocked() {
                return (this._lockcount ? this._unlockDefer.promise : $q.resolve());
            }
        }


        let cache = localforage.createInstance({name: 'sihwCache'});
        let deleteOn = localforage.createInstance({name: 'sihwDeleteOn'});
        let cacheLock = new SihwCacheLock(); //semafoor

        ////////////////////// INTERNE FUNCTIES //////////////////////////////////////////////

        /**
         * Set de cryptkey, of hergebruik de cryptkey die in de sessionstorage zit
         * @param {boolean} reuse Als true dan wordt de nieuwe cryptkey genegeerd als we er nog eentje in sessionstorage hebben
         * @param {string} [cryptkey] De nieuwe cryptkey (als reuse = false of er is er geen). Als niet gegeven, dan maken we een random met objectHash
         * @return $q.deferred.promise
         */
        function setCryptkey(reuse, cryptkey) {
            if (reuse && session.cacheCryptkey) {
                log.debug(`sihwCache. cacheCryptkey hergebruikt: ${session.cacheCryptkey}`);
                return $q.resolve(); //klaar
            }
            //nieuwe
            cryptkey = cryptkey || objectHash.sha1({
                key: 'cck',
                rnd: Math.random(),
                t: Date.now()
            });
            log.debug(`sihwCache. Nieuwe cacheCryptkey hergebruikt: ${cryptkey}`);
            if (session.cacheCryptkey && (session.cacheCryptkey !== cryptkey)) {
                log.debug('sihwcache: Nieuwe cryptkey. Clear cache');
                return clearCache(); //dan maar weg
            }
            session.cacheCryptkey = cryptkey;
            return $q.resolve();
        }

        /**
         * Geef de data in de cache terug, of null als het er niet is (geen reject dan)
         * @param {string | Object} cacheKey
         * @param {string[]} [deleteOnKeys] optionele array met extra deleteOn-opties, zie addCache
         * @returns $q.defer.promise
         */
        function getCache(cacheKey, deleteOnKeys) {
            //cacheKey is een string of een object dat als cache moet dienen, we hashen het dus altijd even
            let cacheKeyHash = objectHash.sha1(cacheKey);
            log.debug('getCache', cacheKeyHash);
            return $q((resolve, reject) => {
                //pas als niet meer gelockt
                log.debug('Check cacheLock', cacheLock.locks);
                cacheLock.unlocked.then(() => {
                    log.debug('cacheLock vrij');
                    cache.getItem(cacheKeyHash).then(data => {
                        if (data) {
                            log.debug('CacheHit voor key', cacheKeyHash);
                            if (deleteOnKeys && deleteOnKeys.length) {
                                //nieuwe deleteOnKeys toevoegen
                                //deleteOn is ook op cacheKey, maar zonder die bulkdata
                                return deleteOn.getItem(cacheKeyHash).then(deldata => {
                                    if (deldata) {
                                        return deldata;
                                    }
                                    else {
                                        return {};
                                    }
                                }).then(deldata => {
                                    for (let key of deleteOnKeys) {
                                        deldata[key] = 1; //toevoegen
                                    }
                                    return deleteOn.setItem(cacheKeyHash, deldata);
                                }).then(() => {
                                    resolve(decryptCache(data, cacheKeyHash)); //als het mislukt wordt de key ook verwijderd
                                });
                            }
                            else {
                                resolve(decryptCache(data, cacheKeyHash)); //als het mislukt wordt de key ook verwijderd
                            }
                        }
                        else {
                            resolve(null);
                        }
                    }).catch(e => {
                        log.error('getCache', e);
                        //we legen de cache na een error
                        clearCache().catch().then(() => {
                            reject(e);
                        });
                    });
                });
            });
        }

        /**
         * Clear en reinit de cache
         * @private
         * @returns $q.deferred.promise
         */
        function clearCache() {
            return cacheLock.lock(() => {
                return $q((resolve, reject) => {
                    return cache.clear().then(() => {
                        return deleteOn.clear().then(resolve);
                    }).catch(reject);
                });
            }, 'clearCache');
        }

        /**
         * Clear delen van de cache gegeven een wijzigingsevent.
         * @param {String|String[]} deleteOnKeys de key(s) die de wijziging beschrijft / beschrijven
         * @returns $q.defer.promise
         */
        function clearCacheOn(deleteOnKeys) {
            //eventTable is gewijzigd, welke caches moeten leeg?

            if (!Array.isArray(deleteOnKeys)) {
                deleteOnKeys = [deleteOnKeys];
            }

            log.debug('clearCacheOn', deleteOnKeys);
            return cacheLock.lock(() => {
                return $q((resolve, reject) => {
                    let teverwijderen = [];
                    return thenEach(deleteOnKeys, deleteOnKey => {
                        return deleteOn.iterate((keyData, cacheKeyHash) => {
                            if (keyData['*'] || keyData[deleteOnKey]) {
                                //deze kan weg
                                log.debug('Te verwijderen', keyData, cacheKeyHash);
                                teverwijderen.push(cacheKeyHash);
                            }
                        }).then(() => true);
                    }).then(() => {
                        //nu nog verwijderen
                        return thenEach(teverwijderen, cacheKeyHash => {
                            return deleteCacheHash(cacheKeyHash).then(() => true); //dit is al een gehashede key
                        });
                    }).then(resolve).catch(reject);
                });
            }, `clearCacheOn(${deleteOnKeys})`);
        }

        /**
         * Voeg toe aan de cache
         * @param {String|Object} cacheKey
         * @param {Array} deleteOnKeys
         * @param {*} data
         * @returns $q.defer.promise
         */
        function addCache(cacheKey, deleteOnKeys, data) {
            //oude hoeft niet weg, we overschrijven
            //we doen nog keurig $q promises

            return encryptCache(data).then(encrypted => {
                if (encrypted === false) {
                    return $q.reject("Niet encryptbaar");
                }
                else {

                    let cacheKeyHash = objectHash.sha1(cacheKey); //maak een hash van de key of het object
                    return cacheLock.lock(() => {

                        log.debug('addCache', cacheKeyHash, deleteOnKeys);
                        return cache.setItem(cacheKeyHash, encrypted).then(() => {
                            let deleteOnObj = {};
                            for (let key of (deleteOnKeys || [])) {
                                deleteOnObj[key] = 1;
                            }
                            return deleteOn.setItem(cacheKeyHash, deleteOnObj).then(() => {
                                return true;
                            })
                        }).catch(function (e) {
                            log.error("addCache", e);
                            //we legen de cache na een error
                            return clearCache().catch().then(() => {
                                $q.reject(e);
                            });
                        });
                    }, `addCache(${cacheKeyHash})`);
                }
            });
        }

        /**
         * Verwijder een key uit de cache
         * @param cacheKey Nog niet gehashede key
         * @returns $q.defer.promise
         */
        function deleteCache(cacheKey) {
            return deleteCacheHash(objectHash.sha1(cacheKey));
        }

        /**
         * Verwijder een key uit de cache
         * @param cacheKeyHash Al gehashede key
         * @returns $q.defer.promise
         */
        function deleteCacheHash(cacheKeyHash) {
            return cacheLock.lock(() => {
                return $q((resolve, reject) => {
                    return cache.removeItem(cacheKeyHash).then(() => {
                        return deleteOn.removeItem(cacheKeyHash);
                    }).then(res => {
                        log.debug(`${cacheKeyHash} verwijderd`);
                        resolve(res);
                    }, reject);
                });
            }, `deleteCacheHash(${cacheKeyHash})`);
        }

        /**
         * Encrypt te cachen data met onze cryptkey (gebaseerd op de eerste inlog/herinlog van de sessie)
         * @param data
         * @returns $q.deferred.promise
         */
        function encryptCache(data) {
            let setkey;
            if (!session.cacheCryptkey) {

                setkey = setCryptkey(false);
            }
            else {
                setkey = $q.resolve();
            }
            return setkey.then(() => {
                return CryptoJS.AES.encrypt(JSON.stringify(data), session.cacheCryptkey).toString();
            }).catch(e => {
                log.warn(e);
                return false;
            })
        }

        /**
         * Decrypt de gegeven cachedata. Als dat mislukt wordt optioneel de opgegeven cachekey verwijderd
         * @param data
         * @param [cacheKeyHash] //al gehashed
         * @returns $q.defer.promise
         */
        function decryptCache(data, cacheKeyHash) {
            return $q((resolve, reject) => {
                if (session.cacheCryptkey) {
                    let res;
                    try {
                        resolve(JSON.parse(CryptoJS.AES.decrypt(data, session.cacheCryptkey).toString(CryptoJS.enc.Utf8)));
                    }
                    catch (_e) {
                        log.warn(_e);
                        //doorvallen
                    }
                }
                //als hier, dan mislukt. We geven null terug, maar deleten evt de key
                if (cacheKeyHash) {
                    resolve(deleteCacheHash(cacheKeyHash));
                }
                reject(null);
            });
        }

        ////////////// SERVICE //////////////////////
        return {
            /**
             * Zet de encryptiekey
             * Als er een expliciete cryptkey wordt meegegeven, dan vervangt deze de bestaande key. Als er geen cryptkey wordt meegegeven, dan wordt een bestaande gebruikt (als deze in de session aanwezig is) of random een nieuwe gemaakt.
             * Als de zo gemaakte of gevonden cryptkey anders is dan de bestaande, volgt een clearCache.
             * Dit betekent dat setCrypt() niet per se een clearCache tot gevolg heeft. Dat is alleen zo als er geen cryptkey in de session was/
             * @param [cryptkey] cryptkey. Als niet gegeven dan blijft een in de sessie bestaande in gebruik, of wordt een random nieuwe gemaakt
             */
            setCrypt(cryptkey) {
                if (cryptkey) {
                    //nooit hergebruiken
                    setCryptkey(false, cryptkey); //de interne
                }
                else {
                    setCryptkey(true); //hergebruik eventuele sessiesleutel
                }
            },

            /**
             * Geef data in de dache terug, of null als het er niet is (geen reject dan).
             * @param {string | Object} cacheKey
             * @param {string[]} [deleteOnKeys] optionele array met extra deleteOn-opties, zie addCache
             * @returns $q.defer.promise
             */
            get(cacheKey, deleteOnKeys) {
                return getCache(cacheKey, deleteOnKeys);
            },

            /**
             * Voeg toe aan de cache
             * @param {String|Object} cacheKey
             * @param {Array} deleteOnKeys
             * @param {*} data
             * @returns $q.defer.promise
             */
            add(cacheKey, deleteOnKeys, data) {
                return addCache(cacheKey, deleteOnKeys, data);
            },
            /**
             * Verwijder een key uit de cache
             * @param {string|Object} cacheKey
             * @returns $q.defer.promise
             */
            delete(cacheKey) {
                return deleteCache(cacheKey); //ruwe sleutel
            },
            /**
             * Leeg de cache
             */
            clear() {
                return clearCache();
            },

            /**
             * Clear delen van de cache gegeven een wijzigingsevent. Roep zoveel mogelijk in 1 keer aan met alle keys, ivm locking
             * @param {String|String[]} deleteOnKeys de key(s) die de wijziging beschrijven
             * @returns $q.defer.promise
             */
            clearOn(deleteOnKeys) {
                return clearCacheOn(deleteOnKeys);
            }
        };
    }]);
