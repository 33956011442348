/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 19-9-2018
 * Time: 13:24
 *
 *
 * Blokkeerscherm
 */

angular.module('sihw.wait', [])
    .factory('sihwWait', ['$timeout', function ($timeout) {
        let bezig = 0; //moduleglobaal aantal lopende promises. Bij 0 kunnen we weer weg
        return function (inpromise) {
            let blok;
            if (!bezig) {
                //de eerste, we maken het waitscherm
                $('body').append(`<div id="sihwWait"><div id="sihwWaiticon"></span></div></div>`);
                blok = $('#sihwWait');
                //en fadein door de opacity nu op 1 te zetten
                $timeout(function () {
                    //zijn we of anderen er nog?
                    if (bezig) {
                        blok.css('opacity', 1);
                    }
                }, 0);
            }
            bezig++;

            let caught = false;
            return inpromise.finally(() => {
                bezig--;
                if (bezig < 1) {
                    blok.one('transitionend', function () {
                        if (!bezig) {
                            $('#sihwWait').remove(); //nu echt weg
                            blok = null;
                        }
                    });
                    console.log('start weghalen blok');
                    blok.css('opacity', 0); //wordt verwijderd als de transitie af is
                    bezig = 0;
                    //voor de zekerheid ook verwijderen als er geen transitie is
                    $timeout(function () {
                        if (blok && (!bezig)) {
                            blok.remove(); //nu echt weg
                        }
                    }, 250);
                }
            });
        }
    }]);