/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 2-10-2017
 * Time: 15:40
 *
 * <any pick-class={class1: cond1, class2: cond2, class3: cond3}>
 *     <any pick-class=[{...},{...}]>
 *
 *         Voegt de eerste class uit het object toe waarvoor de conditie matcht, en de rest niet
 *         In array vorm is het alsof er meerdere pick-class attributes zijn toegevoegd
 */

angular.module('sihw.pickclass.directive',[])
    .directive('pickClass',function() {
        return {
            restrict: 'A',
            link: function($scope,el,attr)
            {
                var doorons = {};
                var $el = $(el);
                var val = attr.pickClass;
                if (! val)
                {
                    return;
                }

                var setjes = Array.isArray(val) ? val : [val];
                angular.forEach(setjes, function(setje) {
                        $scope.$watch(setje,rebuildclasses); //watch de scope-evaluatie
                });

                function rebuildclasses(setje) {
                    //setje is hier de al geevalueerde nieuwe versie van setje

                    if (! setje)
                    {
                        return;
                    }
                    var toegevoegd = false;
                    angular.forEach(setje,function(cond,classname) {
                        if (doorons[classname])
                        {
                           $el.removeClass(classname);
                           delete doorons[classname];
                        }
                        if (toegevoegd)
                        {
                            return; //we hoeven niet te kijken of de volgende conditie ook gaat
                        }

                        if (cond)
                        {
                            if (! $el.hasClass(classname))
                            {
                                doorons[classname] = true;
                                $el.addClass(classname);
                            }
                            toegevoegd = true; //ook als de class er al was
                        }
                    });
                }
            }
        };
    });
