/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 2-10-2017
 * Time: 16:31
 *
 * Bootstrap alert modal met vertaling. Dus ng-translate en uibModal nodig
 * sihwalert(titel, tekst, [buttontekst], [timeout]). Toont een alert. Teksten worden vertaald
 */

angular.module('sihw.alert.service', [
    'pascalprecht.translate',
])
    .factory('sihwalert', ['$uibModal', '$translate', '$timeout', '$sce', function ($uibModal, $translate, $timeout, $sce) {
        return function (title, tekst, buttontekst, timeout) {
            var htimeout;


            return $uibModal.open({
                animation: true,
                template: template(),
                controller: [
                    '$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                        if (timeout) {
                            htimeout = $timeout(function () {
                                $uibModalInstance.dismiss();
                                htimeout = null;
                            }, timeout);
                        }

                        $scope.ok = function () {
                            $uibModalInstance.close();
                        }
                        $scope.escapedTekst = $sce.trustAsHtml($translate.instant(tekst) || "");

                    }
                ]
            }).result.then(function (res) {
                killtimeout();
                return res;
            }).catch(function (err) {
                killtimeout();
                return false; //we rejecten nooit
            });


            function killtimeout() {
                if (htimeout) {
                    $timeout.cancel(htimeout);
                    htimeout = null;
                }
            }


            function template() {
                return "<div class=\"modal-header\"><h3 class=\"modal-title\" translate>" + title + "</h3></div>" +
                    "<div class=\"modal-body\" ng-bind-html='escapedTekst'></div>" +
                    "<div class=\"modal-footer\"><div class=\"text-center\">" +
                    "<button class=\"btn btn-primary\" ng-click=\"ok()\" translate>" + (buttontekst || "OK") + "</button>" +
                    "</div></div>";
            }
        }
    }]);
