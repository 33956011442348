/* 
* normcheck.js
* 30-11-2020 - Jelmer Jellema - Spin in het Web B.V.
*
* State voor normcheck vanuit een domein of project
*
*/

angular.module('dl.normcheck', [
    'sihw.sihwlog',
    'dl.approuter',
    'dl.api',
    'dl.constants'
])
    .config(['approuterProvider', function (approuterprovider) {
        approuterprovider.state('normcheck', {
            url: '/domein/:domein/normcheck',
            templateUrl: 'states/normcheck/normcheck.html',
            controller: 'NormcheckController'
        })
    }])
    .controller('NormcheckController', [
        '$scope', '$stateParams', 'approuter', 'sihwlog', 'api', '__',
        function ($scope, $stateParams, approuter, sihwlog, api, __) {
            //we maken een this, want dat zijn we nogal gewend
            $scope.ctrl = {
                api: api, //we zijn het inmiddels zo gewend
                init() {
                    $scope.log = this.log = sihwlog.logLevel('debug'); //we doen dingen in this om minder fouten te maken ivm veel ontwikkeling in angular2+
                    this.domeinid = $stateParams.domein || $scope.basis.userdata.userdomein;
                    this.Elements = _libs.Elements;
                    this.log.log('NormcheckController', $stateParams, this);
                    if (!(this.domeinid && ($scope.basis.userdata.caps.superadmin || ($scope.basis.userdata.caps.domeinadmin.indexOf(this.domeinid) !== -1)))) {
                        this.log.warn(`Geen toestemming`);
                        approuter.logout();
                        return;
                    }
                    approuter.menudomein(this.domeinid);
                    approuter.menutitel("VIEWTYPE.NORMCHECK");

                    //zorg dat de lijstapi bestaat, vindt de code-checker fijner
                    $scope.lijstapi = $scope.lijstapi || {}; //wordt wel overschreven door de lijst
                    $scope.matchrun = null; //dit wordt straks de run
                    this.initLijst();
                    void this.haaldata();
                },
                /**
                 * Haal de data op
                 * @returns {Promise<void>}
                 */
                async haaldata() {
                    //init
                    if (!await api.isLoggedIn()) {
                        approuter.mainScreen();
                        return;
                    }
                    //alleprojecten van dit domein
                    let res = await api.normcheckdata(this.domeinid);
                    if (!res) {
                        approuter.mainScreen();
                        return;
                    }
                    //we gaan het samenvoegen en gelijk even een basissortering op zetten
                    this.data = [];
                    let normmodels = [], nietnormmodels = [];
                    res.sort((p1, p2) => p1.code.localeCompare(p2.code));
                    for (let project of res) {
                        project.userprojects.sort((up1, up2) => (up1.user.displaynaam || up1.user.username || "").localeCompare(up2.user.displaynaam || up2.user.username || ""));
                        for (let up of project.userprojects) {
                            up.models.sort((a, b) => a.titel.localeCompare(b.titel));
                            for (let model of up.models) {
                                let mdata = {
                                    project: project.code,
                                    user: up.user.displaynaam || up.user.username || "",
                                    model: model.titel,
                                    norm: model.norm,
                                    id: model.idmodel
                                };
                                this.data.push(mdata);
                                //voor de selectielijst
                                if (model.norm) {
                                    normmodels.push(mdata);
                                } else {
                                    nietnormmodels.push(mdata);
                                }
                            }
                        }
                    }

                    $scope.data = this.data;
                    $scope.modellijst = [...normmodels, ...nietnormmodels];
                    $scope.normmodel = null;
                    $scope.$apply(); //async
                },

                /**
                 * Maak de overzichtslijst
                 */
                initLijst() {
                    $scope.overzicht = {
                        track: 'id',
                        selecteer: {
                            // label: '',
                            filterSelect: false //dus modellen buiten filter blijven geselecteerd
                        },
                        velden: {
                            project: {
                                label: __("VIEWTYPE.EDITPROJECT"),
                                type: 'enum'
                            },
                            user: {
                                label: __("CONCEPT.GEBRUIKER")
                            },
                            model: {
                                label: __("CONCEPT.MODEL")
                            }
                        }
                    };
                },

                /**
                 * Begin het maken van het rapport
                 */
                async maakRapport() {
                    /**
                     *
                     * @param {string} str
                     */
                    function up(str) {
                        //helper: maak uppercase
                        if (str.length) {
                            return str[0].toUpperCase() + str.slice(1);
                        }
                        return str;
                    }

                    try {
                        // const rapporttypes = ['topelement', 'quantity', 'attribute', 'quantity_space', 'quantity_space_element', 'q_exo', 'quantity_allvalues', 'configuration', 'q_calc', 'd_calc', 'ineq', 'correspondence', 'causal'];
                        //op deze volgorde wordt er gerapporteerd.
                        const rapporttypes = ['entity', 'agent', 'assumption', 'configuration',
                            'attribute', 'quantity', 'quantity_space', 'quantity_space_element', /*  'q_exo', 'quantity_allvalues',*/  'ineq', 'correspondence', 'causal'];

                        $scope.matchrun = $scope.lijstapi.geselecteerd();
                        for (let model of $scope.matchrun) {
                            model.match = null; //nog niet
                            model.matchresult = __("NORMCHECK.STATUS_WACHT")
                        }
                        //stap 1: haal op wat we nodig hebben
                        let content = await this.api.modelcontent([$scope.normmodel.id, ...$scope.matchrun.map(m => m.id)]);
                        $scope.$apply(); //async
                        this.log.debug('content', Object.keys(content));
                        let norm = content[$scope.normmodel.id];
                        if (!norm) {
                            this.log.warn('Geen toegang tot gekozen normmodel');
                            $scope.matchrun = null; //weg
                            return;
                        }
                        $scope.matching = true; //bezig
                        norm = JSON.parse(norm);

                        //init de xls
                        let wb = await XlsxPopulate.fromBlankAsync();
                        let sheet = wb.sheet(0);

                        //eerst maar eens de basis
                        let col = 1;
                        let rij = 1;
                        sheet.cell(1, col++).value(__('CONCEPT.MODEL')).style('fontColor', '0000FF');
                        sheet.cell(1, col++).value(__('MODEL.TITEL')).style('fontColor', '0000FF');
                        sheet.cell(1, col++).value(__('CONCEPT.GEBRUIKER')).style('fontColor', '0000FF');
                        sheet.cell(1, col++).value(__('VIEWTYPE.EDITPROJECT')).style('fontColor', '0000FF');
                        sheet.cell(1, col++).value(__('NORMCHECK.SCORE')).style('fontColor', '0000FF');
                        let normelementen = []; //op goede volgorde
                        for (let t of rapporttypes) {
                            for (let id of Object.keys(norm.model.elements)) {
                                let e = norm.model.elements[id];
                                if (this.Elements.isSubtypeOf(e.type, t)) {
                                    //this.log.debug(e);
                                    normelementen.push(e);
                                    sheet.cell(1, col).value(up(e.type));
                                    sheet.cell(2, col++).value(this.elementnaam(e, norm.model.elements));
                                }
                            }
                        }
                       /* //de worker wil een hash
                        let normhash = {};
                        for (let normelement of normelementen) {
                            normhash[normelement.id] = normelement;
                        }*/
                        //en nu de aantallen ongematcht
                        for (let t of rapporttypes) {
                            sheet.cell(1, col++).value(`${up(t)} ${__('NORMCHECK.ONGEMATCHT')}`).style('fontColor', 'FF0000');
                        }
                        for (rij = 1; rij < 3; rij++) {
                            sheet.row(rij).style({
                                // fill: 'AAAAAA',
                                bold: true,
                                fontSize: 10,
                                verticalAlignment: 'center'
                            }).height(20);
                        }

                        rij = 3; //ook al gedaan
                        //nu elk model
                        for (let model of $scope.matchrun) {
                            col = 1;
                            sheet.cell(rij, col++).value(model.id).style('fontColor', '0000FF');
                            sheet.cell(rij, col++).value(model.model).style('fontColor', '0000FF');
                            sheet.cell(rij, col++).value(model.user).style('fontColor', '0000FF');
                            sheet.cell(rij, col++).value(model.project).style('fontColor', '0000FF');
                            this.log.debug(`Run ${model.id} ${model.user} ${model.model}`);
                            model.matchresult = __("NORMCHECK.STATUS_BEZIG");
                            $scope.$apply(); //want await
                            let werk;
                            try {
                                werk = JSON.parse(content[model.id]);
                            } catch (e) {
                                this.log.warn(e);
                                continue;
                            }
                            let interpretatie = await this.maakNormcheck(norm.model.elements, werk);
                            if (interpretatie && interpretatie.length) {
                                model.match = interpretatie[0];
                                model.matchresult = __("NORMCHECK.STATUS_KLAAR", {punten: model.match.score});
                                //ok, we moeten kijken hoe de normelementen matchen
                                this.log.debug(model.match);
                                sheet.cell(rij, col++).value(model.match.score).style('fontColor', '0000FF');
                                for (let el of normelementen) {
                                    let mapping = model.match.mappings.find(m => m.norm.id === el.id);
                                    if (!mapping) {
                                        sheet.cell(rij + 2, col++).value('0'); //mapping op 0
                                        continue;
                                    }
                                    sheet.cell(rij, col).value(this.elementnaam(mapping.werk, werk.model.elements)).style('wrapText', true);
                                    let foutmodel = model.match.foutmodel[mapping.werk.id];
                                    if (foutmodel && foutmodel.fouten.length) {
                                        sheet.cell(rij + 1, col).value(foutmodel.fouten.join("\r\n")).style('wrapText', true);
                                        sheet.cell(rij + 2, col).value("0");
                                    } else {
                                        sheet.cell(rij + 2, col).value("1");
                                    }
                                    col++;
                                }
                                //werkelementen zonder mapping
                                for (let t of rapporttypes) {
                                    //hoeveel
                                    sheet.cell(rij, col++).value(Object.keys(model.match.foutmodel).filter(id => {
                                        let foutmodel = model.match.foutmodel[id];
                                        return (!foutmodel.mapping) && this.Elements.isSubtypeOf(foutmodel.el.type, t);
                                    }).length).style('fontColor', 'FF0000');
                                }
                            } else {
                                model.matchresult = __('NORMCHECK.STATUS_FOUT');
                                sheet.cell(rij, col++).value(__('NORMCHECK.STATUS_FOUT')).style('fontColor', 'FF0000');
                            }
                            rij += 3;
                            $scope.$apply(); //want await
                        }
                        //output de excel via de linktruuk
                        let blob = await wb.outputAsync();
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = `Dynalearn ${__('NORMCHECK.RAPPORT')}.xlsx`;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        document.body.removeChild(a);
                        //klaar
                        $scope.matching = false;
                    } catch (e) {
                        this.log.error(e);
                        this.stopmatch();
                    }
                },

                /**
                 * Helper: vind een naam voor een element
                 * @param el Het element
                 * @param elementen alle elementen in de context van el
                 */
                elementnaam(el, elementen) {
                    if (!angular.isObject(el)) {
                        el = elementen[el];
                    }
                    if (!el) {
                        return `?`; //catch all
                    }

                    if (this.Elements.isRelation(el.type)) {
                        //naam? Dan gebruiken we die
                        //ok. We doen de naamgeving van links en rechts
                        return `${el.label || el.type}(${this.elementnaam(el.from, elementen)} - ${this.elementnaam(el.to, elementen)})`;
                    } else {
                        // this.log.debug(el);
                        if (this.Elements.isSubtypeOf(el.type, 'quantity_space_element')) {
                            return (`${this.elementnaam(this.quantity(el, elementen), elementen)}:qs:${el.isZero ? 'zero' : el.label}`);
                        } else if (this.Elements.isSubtypeOf(el.type, 'derivative_element')) {
                            return (`${this.elementnaam(this.quantity(el, elementen), elementen)}:dqs:${el.label}`);
                        }
                        let parent = "";
                        if (el.parentId) {
                            //bij qs-elementen skippen we er een paar
                            parent = this.elementnaam(el.parentId, elementen) + ":";
                        }
                        return `${parent}${el.label || el.type}`;
                    }
                },

                /**
                 * Helper: geef de quantity boven het element terug (of het element zelf), of null als niet
                 * @param el
                 * @param elementen alle elementen in de context van el
                 */
                quantity(el, elementen) {
                    if (!el) {
                        return null;
                    }
                    if (this.Elements.isSubtypeOf(el.type, 'quantity')) {
                        return el;
                    }
                    if (el.parentId) {
                        return this.quantity(elementen[el.parentId], elementen);
                    }
                    return null;
                },


                /**
                 * Maak één interpretatie en foutmodel voor een model en geeft dat terug. Async
                 * Returnt null bij problemen
                 * @param normelementen
                 * @param werk Het geparsede werkmodel
                 * @returns {Promise<any>}
                 */
                maakNormcheck(normelementen, werk) {
                    return new Promise((resolve) => {
                        try {
                            if (this.normMatchWorker) {
                                //die moet nog weg
                                this.log.warn(`normMatchWorker draait nog - killl`);
                                this.normMatchWorker.terminate();
                            }
                            this.normMatchWorker = new Worker("workers/dist/model.normmatch.js");
                            this.normMatchWorker.postMessage({
                                norm: normelementen,
                                werk: werk.model.elements
                            });

                            this.normMatchWorker.onmessage = ev => {
                                this.log.debug('normMatchWorker return', ev.data);
                                this.normMatchWorker.terminate(); //klaar
                                this.normMatchWorker = null;
                                resolve(ev.data);
                            };

                        } catch (e) {
                            if (this.normMatchWorker) {
                                this.normMatchWorker.terminate();
                                this.normMatchWorker = null;
                            }
                            console.error(e);
                            resolve(null);
                        }
                    });
                },

                /**
                 * Stop het matchen: kill de werker en sluit alles
                 */
                stopmatch() {
                    //kill de worker
                    if (this.normMatchWorker) {
                        this.normMatchWorker.terminate();
                        this.normMatchWorker = null;
                    }
                    $scope.matching = false;
                    $scope.matchrun = null; //we zijn weg
                }

            };
            $scope.ctrl.init(); //run de controller
        }
    ]);
