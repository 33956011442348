/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 9-2-2018
 * Time: 09:44
 *
 * Overzicht met af te handelen en afgehandelde aanmeldingen. Superuser-only
 */

angular.module('dl.aanmeldingen', [
    'ui.bootstrap',
    'sihw.sihwlog',
    'dl.approuter',
    'dl.api',
    'sihw.datatable',
    'sihw.alert.service',
    'sihw.controls'
]).config(['approuterProvider', function (approuterProvider) {
    approuterProvider.state('aanmeldingen',
        {
            url: '/aanmeldingen',
            templateUrl: 'states/aanmeldingen/aanmeldingen.html',
            controller: 'AanmeldingenController'
        })
}]).controller('AanmeldingenController', [
    '$scope', '$translate', '$uibModal',
    'approuter', 'sihwlog', 'api',
    function ($scope, $translate, $uibModal,
              approuter, sihwlog, api) {
        let log = sihwlog.logLevel('debug');
        log.log('AanmeldingenController');
        approuter.menutitel("VIEWTYPE.AANMELDINGEN");

        initScope();
        haalData();


        function initScope() {
            //datatable definities
            $scope.openlijst = {
                standaardsortering: {
                    veld: 'tijd',
                    richting: 1
                },
                velden: {
                    type: {
                        label: __('AANMELDINGEN.TYPE._LABEL'),
                        type: 'enum',
                        format: val => __(val ? `AANMELDINGEN.TYPE.${val.toUpperCase()}` : '')
                    },
                    tijd: {
                        label: __('AANMELDINGEN.TIJD'),
                        prop: 'updatedAt', //werkt nl ook okee bij wel afgehandeld
                        type: ['moment', {format: __('FORMAT_DATUMTIJD')}]
                    },
                    email: {
                        label: __('AANMELDINGEN.EMAIL'),
                    },
                    organisatie: {
                        label: __('AANMELDINGEN.ORGANISATIE'),
                        prop: 'organisatie.naam'
                    },
                    plaats: {
                        label: __('AANMELDINGEN.PLAATS'),
                        prop: 'organisatie.plaats'
                    },
                    land: {
                        label: __('AANMELDINGEN.LAND'),
                        prop: 'organisatie.land'
                    },
                    aanvrager: {
                        label: __('AANMELDINGEN.AANVRAGER'),
                        type: 'format',
                        format: rec => {
                            let res = "";
                            if (!rec.persoonlijk) {
                                return res;
                            }
                            if (rec.persoonlijk.voornaam) {
                                res = `${rec.persoonlijk.voornaam} `;
                            }
                            res += (rec.persoonlijk.achternaam || "");
                            return res;
                        }
                    }
                }
            };

            $scope.afgehandeldlijst = angular.copy($scope.openlijst);
            //maar status erbij
            $scope.afgehandeldlijst.velden.status = {
                label: __('AANMELDINGEN.STATUS._LABEL'),
                type: 'enum',
                format: val => __(val ? `AANMELDINGEN.STATUS.${val.toUpperCase()}` : "")
            };

            $scope.afgehandeldlijst.standaardsortering = {
                veld: 'tijd',
                richting: -1
            };

            //ophalen bij nieuwe registratie
            $scope.$on('api.notify.nieuweRegistratie', haalData);
        }

        function haalData() {
            function mapjsonprops(records, prop) {
                for (let rec of (records || [])) {
                    try {
                        rec[prop] = rec[prop] ? JSON.parse(rec[prop]) : {};
                    }
                    catch (_e) {
                        rec[prop] = {};
                    }
                }
            }

            api.aanmeldingen().then(aanmeldingen => {
                if (!aanmeldingen) {
                    //mislukt
                    approuter.mainScreen();
                }
                else {
                    mapjsonprops(aanmeldingen.ingediend, 'persoonlijk');
                    mapjsonprops(aanmeldingen.ingediend, 'organisatie');
                    mapjsonprops(aanmeldingen.afgehandeld, 'persoonlijk');
                    mapjsonprops(aanmeldingen.afgehandeld, 'organisatie');
                    mapjsonprops(aanmeldingen.afgehandeld, 'afhandeling');
                    $scope.open = aanmeldingen.ingediend;
                    log.debug($scope.open);
                    $scope.afgehandeld = aanmeldingen.afgehandeld;
                }
            }).catch(e => {
                approuter.mainScreen();
            });
        }

        $scope.openAanmelding = function (aanmelding) {
            //open de aanmelding in een dialoog

            $uibModal.open({
                animation: true,
                backdrop: 'static',
                windowClass: 'aanmeldingen_detail',
                templateUrl: '/states/aanmeldingen/detail.html',
                scope: $scope, //sub van de gewone, ivm __
                controller: [
                    '$scope', '$uibModalInstance', 'sihwalert',
                    function ($scope, $uibModalInstance, sihwalert) {
                    //lijst met alle bestaande domeinnamen:
                    $scope.bestaandedomeinen = ($scope.basis.domeinen.arr || []).map(d => d.naam);
                        $scope.aanmelding = angular.copy(aanmelding); //we werken in een kopie

                        //model in het formulier, voor weergave of als men nog mag wijzigen
                        $scope.afhandeling = angular.extend({},
                            {
                                domein:  aanmelding.type === 'material' ? '_material' : (aanmelding.organisatie.voorkeursdomein || aanmelding.organisatie.naam || "Nieuwe organisatie").replace(/\s/g,'').toLocaleLowerCase(),
                                email: aanmelding.email,
                                naam: (aanmelding.persoonlijk.voornaam ? aanmelding.persoonlijk.voornaam + " " : "") + aanmelding.persoonlijk.achternaam
                            },
                            aanmelding.afhandeling || {});

                        $scope.afhandelen = function()
                        {
                            //blijkbaar okee gevalideerd, dus gaan
                            api.afhandelenAanmelding(aanmelding.id, $scope.afhandeling).then(() =>{
                                sihwalert('AANMELDINGEN.DETAIL.AFHANDELING.GELUKT', `AANMELDINGEN.DETAIL.AFHANDELING.GELUKT_UITLEG_${$scope.afhandeling.status.toUpperCase()}`, "BTN_CONFIRM");
                                $uibModalInstance.close();
                                haalData(); //opnieuw ophalen
                            }).catch(e => {
                                log.error(e);
                                $scope.error = (e && e.code) || "ALGEMEEN"; //wordt een translatekey
                            });
                        }
                    }
                ]
            }).result.catch(function () {
            }); //noop

        };

        /**
         * Helper voor snelle vertaling
         * @param args
         * @return {string|Object}
         * @private
         */
        function __(...args) {
            return $translate.instant(...args);
        }

        $scope.__ = __;
    }
]);
