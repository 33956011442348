/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 2-10-2017
 * Time: 15:40
 *
 * <any pick-attr={attr: '...', [translate: true], pick: [{if: cond1, then: 'val1'},{if: cond2, then: 'val2'},....]}>
 *     <any pick-class=[{...},{...},....]>
 *
 *         Set het gegeven attribute de eerste waarde waarvoor de conditie matcht, eventueel via translate
 *         In array vorm is het alsof er meerdere pick-attr attributes zijn toegevoegd
 */

angular.module('sihw.pickattr.directive',[
    'pascalprecht.translate'
])
    .directive('pickAttr',['$translate','sihwlog',  function($translate, sihwlog) {
        return {
            restrict: 'A',
            link: function($scope,el,attr)
            {
                var log = sihwlog.logLevel('warn');
                log.debug('pickAttr',attr.pickAttr);
                var doorons = {};
                var $el = $(el);
                var val = attr.pickAttr;
                if (! val)
                {
                    return;
                }

                var setjes = Array.isArray(val) ? val : [val];
                angular.forEach(setjes, function(setje) {
                        $scope.$watch(setje,rebuildAttrValues); //watch de scope-evaluatie
                });

                function rebuildAttrValues(setje) {
                    //setje is hier de al geevalueerde nieuwe versie van setje

                    log.debug('pickAttr test setje',setje);
                    if (! (setje && setje.attr))
                    {
                        return;
                    }

                    var toegevoegd = false;
                    if (setje.attr in doorons)
                    {
                        //wij hadden hem toegevoegd. Voor nu zetten we een eventuele oude waarde terug
                        $el.attr(setje.attr,doorons[setje.attr]); //kan ook null zijn, dan verwijderen
                        delete doorons[setje.attr]; //weg
                    }
                    //okee, nu picken, de eerste die pickt telt
                    try {
                        angular.forEach(setje.pick, function (test) {
                            log.debug('pickAttr test test',test);
                            if (test.if) {
                                //deze wordt het
                                log.debug('JA deze');
                                doorons[setje.attr] = $el.attr(setje.attr); //levert null als er niets was
                                $el.attr(setje.attr, setje.translate ? $translate.instant(test.then) : test.then); //setten
                                throw "break";
                            }
                        });
                    }
                    catch(_e) {} //break
                }
            }
        };
    }]);
