/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 18-4-2018
 * Time: 12:02
 *
 *
 */
angular.module('sihw.targetblank',[])
    .factory('targetblank',['$timeout',function($timeout) {
        return function(url)
        {
            let id=`sihwtargetblank_${Date.now()}`;
            $('body').append(`<a id="${id}" href="${url}" target="_blank">&nbsp;</a>`);
            let a=$(`#${id}`)[0];
            a.click();
            $timeout(function() {a.remove();},5);
        }
    }]);