/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 29-11-2017
 * Time: 14:25
 *
 *
 */


'use strict';

angular.module('sihw.thenloops', [])
/**
 thenEach functie
 */
    .factory('thenEach', ['$q', function ($q) {
        /**
         * Loop over elk element van een array, maar steeds pas de volgende als de functie resolved is
         * @param {Array} loopOver
         * @param {function} fn
         * @param {*} [fnthis] De this van de functie
         */
        function thenEach(loopOver, fn, fnthis) {
            /**
             * Die 1 stap in de thenLoop
             * @private
             * @param loopOver
             * @param _len intern: de length van loopOver
             * @param index
             * @param fn
             * @param fnthis
             */
            function _doeLoop(loopOver, _len, index, fn, fnthis) {
                return $q.resolve(fn.apply(fnthis, [loopOver[index]])).then(
                    function (res) {
                        if (res === false) {
                            return false; //we stoppen en geven false terug
                        }
                        else {
                            //door, althans, als er nog is. Dit doen we voor de nieuwe aanroep, scheelt een context
                            if (++index >= _len) {
                                return true; //klaar en alles ok
                            }
                            return _doeLoop(loopOver, _len, index, fn, fnthis);
                        }
                    }
                ); //reject wordt gewoon teruggegeven
            }

            //en begin de recursief
            let _len = loopOver.length;
            if (!_len) {
                //klaar
                return $q.resolve(true);
            }
            return _doeLoop(loopOver, _len, 0, fn, fnthis);
        }

        return thenEach;
    }])
    /**
     * thenWhile(function(), [this-voor-function])
     */
    .factory('thenWhile', ['$q', function ($q) {
        /**
         * Run de functie, en als deze niet (een promise van) false teruggeeft, run hem opnieuw
         * @param fn De functie
         * @param [fnthis] De this in de functie
         */
        function thenWhile(fn,fnthis)
        {
            return $q.resolve(fn.apply(fnthis)).then(
                function(res)
                {
                    if (res === false)
                    {
                        return true; //we stoppen en geven true terug, alles is goed gegaan
                    }
                    else
                    {
                        //doorgaan
                        return thenWhile(fn,fnthis);
                    }
                }
            )
        }

        return thenWhile;
    }]);