/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 27-2-2018
 * Time: 11:38
 *
 */

angular.module('sihw.controls', ['sihw.angular.config', 'sihw.sihwlog'])
    .directive('datumVeld',['sihwAngularConfig','sihwlog', function(sihwAngularConfig, sihwlog) {
        let log = sihwlog.logLevel(sihwAngularConfig.loglevel('controls'));
        log.debug('sihw.controls: datumVeld');
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function(scope, elm, attrs, ctrl) {
                log.debug(`link datumVeld`, elm, attrs, ctrl);

                /**
                 * Parset en valideert een value
                 * @param val
                 */
                function parseValue(val) {
                    let m = moment(val, attrs.format || "DD-MM-YYYY");
                    if (! m.isValid())
                    {
                        return false;
                    }
                    return m.format('YYYY-MM-DD'); //intern data
                }
                ctrl.$render = function() {
                    log.debug(`render ${ctrl.$modelValue}`);
                    if (ctrl.$modelValue && ctrl.$modelValue.length) {
                        elm.val(moment(ctrl.$modelValue).format(attrs.format || "DD-MM-YYYY"));
                    }

                }

                ctrl.$parsers.push(value => {
                    log.debug(`parse ${value}`);
                    if (ctrl.$isEmpty(value))
                    {
                        return null;
                    }
                    return parseValue(value);
                });

                ctrl.$validators.datumVeld = function(modelvalue, viewvalue) {
                    if (ctrl.$isEmpty(modelvalue))
                    {
                        return true;
                    }
                    return !! parseValue(viewvalue);
                }
            }
        }
    }])

    .directive('tijdVeld', ['sihwAngularConfig', 'sihwlog', function (sihwAngularConfig, sihwlog) {
        let log = sihwlog.logLevel(sihwAngularConfig.loglevel('controls'));
        log.debug('sihw.controls: tijdVeld');
        return {
            require: 'ngModel',
            restrict: 'A',
            link: function (scope, elm, attrs, ctrl) {

                /**
                 * Parse de tijd in het invoerveld, geef false, een lege string of een volledige tijdstring terug
                 * @param tijdstring
                 */
                function parseTijd(tijdstring) {
                    if (ctrl.$isEmpty(tijdstring)) {
                        return ""; //dat mag
                    }
                    //1 of 2 uren, 2 minuten, eventueel een scheiding
                    var m;
                    if (m = /^\s*(\d{1,2}):?(\d{2})$/.exec(tijdstring)) {
                        //zou kunnen
                        var uur = parseInt(m[1], 10);
                        var minuut = parseInt(m[2], 10);
                        if (uur < 24 && minuut < 60) {
                            var retval = "";
                            if (uur < 10) {
                                retval = "0";
                            }
                            retval += uur + ":";
                            if (minuut < 10) {
                                retval += "0";
                            }
                            retval += minuut;
                            return retval;
                        }
                    }
                    //anders is het mis
                    return false;
                }

                //we voegen een tijd-validator toe
                ctrl.$validators.tijdVeld = function (modelvalue, viewvalue) {
                    return !(parseTijd(viewvalue) === false);
                };
                //en even goed in het model en de view zetten
                ctrl.$parsers.push(function (value) {
                    return parseTijd(value);
                });

                ctrl.$formatters.push(function (value) {
                    //run even door parseTijd
                    let parsed = parseTijd(value);
                    return parsed || value;
                });

                elm.on('blur', function () {
                    //even goedzetten
                    if (ctrl.$modelValue) {
                        elm.val(ctrl.$modelValue);
                    }
                });
            }
        }
    }])
    .directive('aantalVeld', ['sihwAngularConfig', 'sihwlog', function (sihwAngularConfig, sihwlog) {
        let log = sihwlog.logLevel(sihwAngularConfig.loglevel('controls'));
        log.debug('sihw.controls: aantalVeld');
        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
              min: '=',
              max: '='
            },
            link: function (scope, elm, attrs, ctrl) {
                var test = /^\s*(\d+)\s*$/;
                ctrl.$validators.aantalVeld = function (modelvalue, viewvalue) {
                    if (ctrl.$isEmpty(modelvalue)) {
                        return true; //ok
                    }
                    let m=  viewvalue.match(test);
                    if (! m)
                    {
                        return false;
                    }
                    let val = parseInt(m[1],10);
                    return (scope.min == null || val >= scope.min) && (scope.max == null || val <= scope.max);
                };

                //parse invoer
                ctrl.$parsers.push(value => {
                    if (ctrl.$isEmpty(value)) {
                        return null;
                    } else if (test.test(value)) {
                        //maak er een int van
                        let intval = parseInt(value, 10);
                        return isNaN(intval) ? false : intval;
                    } else {
                        return false; //niet door de test
                    }
                });

                /**
                 * Formatter voor $formatters en na blur: formateer het aantalveld
                 * @param value
                 */
                function formataantalveld(value) {
                    if (ctrl.$isEmpty(value)) {
                        return "";
                    } else {
                        return value.toString();
                    }
                }

                ctrl.$formatters.push(formataantalveld);

                //en even goed weergeven na wijzigingen in ui
                elm.on('blur', function () {
                    log.debug('aantalveld', ctrl.$viewValue, ctrl.$modelValue);
                    if (ctrl.$modelValue) {
                        elm.val(formataantalveld(ctrl.$modelValue));
                    }
                });
            }
        }
    }]).directive('decimaalVeld', ['sihwAngularConfig', 'sihwlog', function (sihwAngularConfig, sihwlog) {
    let log = sihwlog.logLevel(sihwAngularConfig.loglevel('controls'));
    log.debug('sihw.controls: decimaalVeld');
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, elm, attrs, ctrl) {
            //test of deze voldoet aan het patroon
            function test(val) {
                let re = ('signed' in attrs) ? /^\s*(-?\d*)([,.](\d+))?\s*$/ : /^\s*(\d*)([,.](\d+))?\s*$/;
                return re.test(val);
            }

            ctrl.$validators.decimaalVeld = function (modelvalue, viewvalue) {
                if (ctrl.$isEmpty(modelvalue)) {
                    return true; //ok
                }
                return test(viewvalue);
            };

            //parse de invoer richting de modelwaarde
            ctrl.$parsers.push(value => {
                if (ctrl.$isEmpty(value)) {
                    return null;
                } else if (test(value)) {
                    //geldige waarde, omzetten naar float
                    //maak er een float van
                    value = value.replace(/[,.]/, '.');
                    let floatval = parseFloat(value);
                    if (!isNaN(floatval)) {
                        //zijn er teveel decimalen?
                        if ('maxDecimalen' in attrs) {
                            let maxdecimalen = parseInt(attrs.maxDecimalen, 10);
                            if ((!isNaN(maxdecimalen)) && maxdecimalen >= 0) {
                                let factor = Math.pow(10, maxdecimalen);
                                floatval = Math.round(floatval * factor) / factor;
                            }
                        }
                        return floatval;
                    } else {
                        return false; //nan geven we als fout terug
                    }
                } else {
                    return false; //ongeldige waarde
                }
            });

            /**
             * Formatter voor $formatters en na blur: formateer het decimaalveld
             * @param value
             */
            function formatdecimaalveld(value) {
                //weergave van een interne waarde
                if (ctrl.$isEmpty(value)) {
                    return ""; //leeg
                }
                //alle mogelijke decimaalscheidingen aanpassen
                let decimaalveld = attrs.decimaalVeld || ',';
                let strval = value.toString().replace(/[,.]/, decimaalveld);
                let geheel, decimalen;
                [geheel, decimalen] = strval.split(decimaalveld, 2);
                geheel = geheel || "0";
                decimalen = decimalen || "";
                let numdecimalen = decimalen ? decimalen.length : 0;
                if ('minDecimalen' in attrs) {
                    let mindecimalen = parseInt(attrs.minDecimalen, 10);
                    if ((!isNaN(mindecimalen)) && mindecimalen > 0 && mindecimalen > numdecimalen) {
                        decimalen += "".padEnd(mindecimalen - numdecimalen, '0');
                        numdecimalen = mindecimalen;
                    }
                }
                if ('maxDecimalen' in attrs) {
                    //we ronden niet af, we hakken af
                    let maxdecimalen = parseInt(attrs.maxDecimalen);
                    if ((!isNaN(maxdecimalen)) && maxdecimalen >= 0 && numdecimalen > maxdecimalen) {
                        decimalen = decimalen.substr(0, maxdecimalen);
                        numdecimalen = maxdecimalen;
                    }
                }
                if (decimalen.length) {
                    return `${geheel}${decimaalveld}${decimalen}`;
                } else {
                    return geheel;
                }
            }

            ctrl.$formatters.push(formatdecimaalveld);

            elm.on('blur', function () {
                //even bijwerken wat de interne formatter ervan gemaakt heeft
                log.debug('decimaalveld blur', ctrl.$viewValue, ctrl.$modelValue);
                if (!ctrl.$isEmpty(ctrl.$modelValue)) {
                    elm.val(formatdecimaalveld(ctrl.$modelValue));
                }
            });
        }
    }
}])
    .directive('nietIn', ['sihwAngularConfig', 'sihwlog', function (sihwAngularConfig, sihwlog) {
        let log = sihwlog.logLevel(sihwAngularConfig.loglevel('controls'));
        log.debug('sihw.controls: niet-in');

        return {
            require: 'ngModel',
            restrict: 'A',
            scope: {
                nietIn: '<?', //array met waarden waar deze input ongelijk aan moet zijn
                nietInCase: '<?' //case-sensitive? default = nee
            },
            link: function ($scope, elm, attrs, ctrl) {
                ctrl.$validators.nietIn = function (_modelvalue, viewvalue) {
                    return checkWaarde(viewvalue);
                };

                $scope.$watchGroup(['nietIn', 'nietInCase'], recheck);

                function recheck() {
                    ctrl.$validate();
                }

                function checkWaarde(val) {
                    //empty is altijd okee
                    if (ctrl.$isEmpty(val) || !($scope.nietIn && $scope.nietIn.length)) {
                        return true;
                    }

                    //anders alleen als hij niet in de array zit, maar met sloppy ==
                    if ($scope.nietInCase) {
                        return !$scope.nietIn.find(el => el == val);
                    } else {
                        return !$scope.nietIn.find(el => {
                            try {
                                return el.toLocaleString().toLocaleLowerCase() == val.toLocaleString().toLocaleLowerCase();
                            } catch (_e) {
                                return false;
                            }
                        });
                    }
                }
            }
        };
    }])
    .directive("clickRepeat", ['$interval', '$window', 'sihwAngularConfig', 'sihwlog',
        function ($interval, $window, sihwAngularConfig, sihwlog) {
            let log = sihwlog.logLevel(sihwAngularConfig.loglevel('controls'));
            log.debug('sihw.controls: click-repeat');
            return {
                restrict: 'A',
                scope: {
                    'clickRepeat': '&',
                    'repeatMs': '<?',
                    'speedupAfter': '<?',
                    'speedupMs': '<?'
                },
                link: function ($scope, el) {
                    let runner = null; //intervalid
                    let counter = 0; //aantal keer repeat
                    let globalevents = false;
                    let touchevents = ('ontouchstart' in document.documentElement);
                    el = $(el);
                    //we willen niet mouse en touch opvangen, want dat geeft dubbele events. Dus testen we
                    el
                        .on('contextmenu', e => {
                            if (e.cancelable) {
                                e.preventDefault();
                            }
                        })
                        .on(touchevents ? 'touchstart' : 'mousedown', function (e) {
                            log.debug('START down', e.type);
                            //we doen geen preventdefault, want dan werkt scroll-drag niet meer op telefoons
                            //in plaats daarvan vangen we contextmenu hierboven op, die anders op desktop verschijnt
                            /*     if (e.cancelable) {
                                 //  e.preventDefault();
                                 }*/
                            if (el.is(':disabled')) {
                                return;
                            }
                            if (!runner) { //anders loopt hij al
                                log.debug('Start click-repeat runner');
                                counter = 0;
                                setRunner($scope.repeatMs || 500);
                                if (!globalevents) {
                                    //van drag op, (scrollen met vinger op knop)
                                    $($window).on('touchmove', onTouchmove);
                                    globalevents = true;
                                }
                            }
                        })
                        .on(touchevents ? 'touchend' : 'mouseup', function (e) {
                            log.debug('END down', e.type);
                            stop(true);
                        })
                        .on('mouseout', function () {
                            log.debug('mouseout');
                            stop(false);
                        });
                    $scope.$on('$destroy', function () {
                        stop(false);
                    });


                    /**
                     * Vang window.onTouchmove op: dan is het dragscroll, geen click
                     */
                    function onTouchmove() {
                        log.debug('touchmove');
                        stop(false);
                    }

                    /**
                     * Start of reset de runnerinterval
                     * @param ms
                     */
                    function setRunner(ms) {
                        if (runner) {
                            $interval.cancel(runner);
                        }
                        runner = $interval(function () {
                            doeClick();
                        }, ms);
                    }

                    /**
                     * Doe een click, als we enabled zijn
                     */
                    function doeClick() {
                        if (el.is(':disabled')) {
                            return;
                        }

                        counter++;
                        $scope.clickRepeat();
                        if ($scope.speedupAfter && counter === $scope.speedupAfter) {
                            //tijd voor versnelling
                            setRunner($scope.speedupMs || 250);
                        }
                    }

                    /**
                     * Stop de repeat. Als runAlsClick true is, zorgen we dat de handler minstens 1 keer runt, zodat een reguliere klik ook gebeurt
                     * @param runAlsClick
                     */
                    function stop(runAlsClick) {
                        if (runner) {
                            log.debug('Stop click-repeat runner');
                            $interval.cancel(runner);
                            runner = null;
                            if (runAlsClick && (!counter)) {
                                log.debug('click-repeat: single click handler');
                                doeClick();
                                $scope.$apply();
                            }
                        }
                        if (globalevents) {
                            $($window).off('touchmove', onTouchmove);
                            log.debug('Cancel touchmove');
                            globalevents = false;
                        }
                    }
                }
            };
        }
    ]);
