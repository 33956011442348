/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 26-8-2024
 *
 * Algemeen dashboard (v3)
 */

angular.module('dl.dashboard', [
    'sihw.sihwlog',
    'dl.approuter',
    'dl.api'
])
    .config(['approuterProvider', function (appRouterProvider) {
        appRouterProvider.state('dashboard', {
            url: '/dashboard',
            templateUrl: 'states/dashboard/dashboard.html',
            controller: 'DashboardController'
        })
    }])

    .controller('DashboardController', [
        '$scope', '$translate', 'approuter', 'sihwlog', 'api',
        function ($scope, $translate, approuter, sihwlog, api) {
            let log = sihwlog.logLevel('debug');
            let bijwerkDebounce = null;
            log.log('Dashboardcontroller');
            approuter.menutitel("DASHBOARD.MENUTITEL");

            initScope();

            function initScope() {
                $scope.selectdomeinen = new Set(); //geselecteerde domeinids
                $scope.toonprojecten = []; //welke projecten te tonen
                $scope.selectprojecten = new Set(); //geselecteerde projectids
                $scope.toonusers = [];
                $scope.selectusers = new Set();
                $scope.toonmodellen = [];
                $scope.selectmodellen = new Set();
                //waar gaan we op groeperen?
                $scope.groepeer = {
                    domein: false,
                    project: false,
                    user: false
                };
                $scope.exportActionlog = false;
                $scope.groepen = {
                    elementen: true,
                    normtelling: false,
                    acties: true,
                    undoredo: false,
                    uniekefouten: false,
                    vraagteken: false,
                    sim: true,
                    feedbackroutes: false,
                    uitroepteken: false,
                    normtelling_details: false,
                    acties_details: false,
                    uniekefouten_details: false,
                    vraagteken_details: false,
                    sim_details: false,
                    feedbackroutes_details: false,
                    uitroepteken_details: false,
                    videoclips: false,
                    duraties: false,
                    legemodellen: false,
                    geennorm: false
                }

            }


            ///toggle domein
            $scope.toggleDomein = function (domein) {
                if ($scope.selectdomeinen.has(domein.id)) {
                    $scope.selectdomeinen.delete(domein.id);
                } else {
                    $scope.selectdomeinen.add(domein.id);
                }
                planBijwerken(true);
            }

            /**
             * Selecteer alle domeinen
             */
            $scope.domeinAlles = function () {
                $scope.selectdomeinen = new Set(Object.keys($scope.basis.domeinen.hash));
                planBijwerken(true);
            }

            $scope.domeinNiks = function () {
                $scope.selectdomeinen.clear();
                planBijwerken(true);
            }

            //toggle project
            $scope.toggleProject = function (project) {
                if ($scope.selectprojecten.has(project.id)) {
                    $scope.selectprojecten.delete(project.id);
                } else {
                    $scope.selectprojecten.add(project.id);
                }
                planBijwerken(false, true);
            }

            $scope.projectAlles = function () {
                $scope.selectprojecten = new Set($scope.toonprojecten.map(p => p.id));
                planBijwerken(false, true);
            }

            $scope.projectNiks = function () {
                $scope.selectprojecten.clear();
                planBijwerken(false, true);
            }

            //toggle user
            $scope.toggleUser = function (user) {
                if ($scope.selectusers.has(user.iduser)) {
                    $scope.selectusers.delete(user.iduser);
                } else {
                    $scope.selectusers.add(user.iduser);
                }
                planBijwerken(false, false, true);
            }

            $scope.userAlles = function () {
                $scope.selectusers = new Set($scope.toonusers.map(u => u.iduser));
                planBijwerken(false, false, true);
            }

            $scope.userNiks = function () {
                $scope.selectusers.clear();
                planBijwerken(false, false, true);
            }

            //toggle model
            $scope.toggleModel = function (model) {
                if ($scope.selectmodellen.has(model.idmodel)) {
                    $scope.selectmodellen.delete(model.idmodel);
                } else {
                    $scope.selectmodellen.add(model.idmodel);
                }
            }

            $scope.modelAlles = function () {
                $scope.selectmodellen = new Set($scope.toonmodellen.map(m => m.idmodel));
            }

            $scope.modelNiks = function () {
                $scope.selectmodellen.clear();
            }

            /**
             * debounce het bijwerken, heel kort, want we willen niet iets killen dat met andere args draait
             * @param {boolean} domeinen //domeinen zijn gewijzigd
             * @param {boolean} [projecten] //projecten zijn gewijzigd
             * @param {boolean} [users] //projecten zijn gewijzigd
             */
            function planBijwerken(domeinen, projecten, users) {
                if (bijwerkDebounce) {
                    clearTimeout(bijwerkDebounce);
                }
                bijwerkDebounce = setTimeout(function () {
                    bijwerkenLijsten(domeinen, projecten, users);
                }, 50);
            }

            /**
             * Na een toggle wordt dit met een kleine rebounce aangeroepen. Werk alle lijsten bij en haal benodigde data op
             * @param {boolean} domeinen //domeinen zijn gewijzigd
             * @param {boolean} [projecten] //projecten zijn gewijzigd
             * @param {boolean} [users] //projecten zijn gewijzigd
             * @returns {promise<void>}
             */
            async function bijwerkenLijsten(domeinen, projecten, users) {
                if (domeinen) {
                    log.debug(`Domeinselectie gewijzigd - update projecten`);
                    //domeinen zijn gewijzigd, we halen de nieuwe projectlijst op
                    let projecthash;
                    if ($scope.selectdomeinen.size) {
                        projecthash = await api.projecten(Array.from($scope.selectdomeinen)); //hash!
                    } else {
                        projecthash = {};
                    }

                    $scope.toonprojecten = Object.values(projecthash);

                    //zit er in selectprojecten een ongeldige selectie?
                    for (let sp of $scope.selectprojecten) {
                        if (!projecthash[sp]) {
                            //die moet eruit
                            $scope.selectprojecten.delete(sp);
                            projecten = true; //we moeten de boel hieronder verversen
                        }
                    }
                }
                if (projecten) {
                    log.debug(`Projectselectie gewijzigd - update gebruikers`);
                    if ($scope.selectprojecten.size) {
                        $scope.toonusers = await api.datadashboard_usersmodellen(Array.from($scope.selectprojecten));
                        log.debug($scope.toonusers);
                    } else {
                        $scope.toonusers = [];
                    }
                    //we moeten ook echt de users en modellen weer doen, want daar kunnen users en modellen uit meerdere projecten in zitten
                    users = true;

                    //zit er in selectusers een ongeldige selectie?
                    for (let uid of $scope.selectusers) {
                        if (!$scope.toonusers.find(u => u.iduser === uid)) {
                            //die moet eruit
                            $scope.selectusers.delete(uid);
                        }
                    }
                }

                if (users) {
                    log.debug(`Userselectie gewijzigd - update modellen`);
                    //even weer de user toevoegen
                    $scope.toonmodellen = [];
                    for (let u of $scope.toonusers) {
                        if ($scope.selectusers.has(u.iduser)) {
                            for (let m of u.modellen) {
                                $scope.toonmodellen.push(m);
                                m.user = u;
                            }
                        }
                    }
                    $scope.toonmodellen.sort((a, b) => a.titel.localeCompare(b.titel));
                    //even de ongeledige selectmodellen eruit
                    //zit er in selectusers een ongeldige selectie?
                    for (let mid of $scope.selectmodellen) {
                        if (!$scope.toonmodellen.find(m => m.idmodel === mid)) {
                            //die moet eruit
                            $scope.selectmodellen.delete(mid);
                        }
                    }
                    log.debug($scope.toonmodellen);
                    log.debug($scope.selectmodellen);
                }

                api.asyncreturn(); //zorg voor bijwerken ux
            }

            /**
             * Geef het niveau van groeperen terug
             * @returns {string}
             */
            function groepeerOp() {
                for (let key of ['domein', 'project', 'user']) {
                    if ($scope.groepeer[key]) {
                        return key;
                    }
                }
                return 'model';
            }

            /**
             * Geef de selectieset waarop gegroeperd op terug
             * @returns {Set}
             */
            function selectieSet() {
                switch (groepeerOp()) {
                    case 'domein':
                        return $scope.selectdomeinen;
                    case 'project':
                        return $scope.selectprojecten;
                    case 'user':
                        return $scope.selectusers;
                    default:
                        return $scope.selectmodellen;
                }
            }

            /**
             * Geef de keys van de groepen terug die zijn aangevinkt
             */
            function actieveGroepen() {
                return Object.keys($scope.groepen).filter(groep => $scope.groepen[groep]);
            }

            /**
             * Geeft true als we genoeg info hebben voor de export
             */
            $scope.klaarVoorExport = function () {
                return (!!selectieSet().size) && ((!!actieveGroepen().length) || $scope.exportActionlog);
            }
            /**
             * Voer de export uit
             */
            $scope.export = async function () {
                const groepeer = groepeerOp();
                const Elements = _libs.Elements; //hierarchie e.d.. Zie lib/elements.js
                const telacties = [['create', true], ['modify', true], ['delete', true]] //getelde actie, en moeten we uitsplitten per norm-elementtype
                const data = await api.datadashboard_data(groepeer, Array.from(selectieSet()), !$scope.groepen.legemodellen, !$scope.groepen.geennorm, $scope.exportActionlog);

                let wb = await XlsxPopulate.fromBlankAsync();

                if (actieveGroepen().length) {
                    //het datadashboard zelf

                    let sheet = wb.addSheet('DataDashboard');

                    let numsessies = 0; //de koppen voor sessies doen we als we alle modellen hebben gedaan
                    let sessiecol; //de kolom waar die koppen beginnen

/////////////////////////////////////  VELDEN //////////////////////////////
                    //welke velden gaan we afbeelden?
                    const velden = [
                        ['Domein', 'Naam van het domein']
                    ];
                    //we schrijven de velden even per niveau uit, voor de leesbaarheid
                    switch (groepeer) {
                        case 'domein':
                            velden.push(['Projecten', 'Aantal projecten in telling'], ['Gebruikers', 'Aantal gebruikers in telling'], ['Modellen', 'Aantal modellen in telling']);
                            break;
                        case 'project':
                            velden.push(['Project', 'Projectcode'], ['Gebruikers', 'Aantal gebruikers in telling'], ['Modellen', 'Aantal modellen in telling']);
                            break;
                        case 'user':
                            velden.push(['Leerling', 'Opgeslagen naam'],
                                ['Id', 'Interne anonieme ID'],
                                ['Projecten', 'Aantal projecten in telling'], ['Modellen', 'Aantal modellen in telling']);
                            break;
                        case 'model':
                            velden.push(['Project', 'Projectcode'], ['Leerling', 'Opgeslagen naam'],
                                ['Id', 'Interne anonieme ID'],
                                ['Model_name', 'Door leerling gekozen modelnaam'],
                                ['Model_id', 'Interne ID van model'],
                                ['Samenwerking_op', 'ID van bewerkte model in samenwerking'],
                                ['Created', 'Datum van aanmaken'],
                                ['Last', 'Datum van laatste wijziging']
                            );
                            break;
                    }

                    //aantal acties
                    velden.push(['Actionlog', `Totaal aantal regels in de ${groepeer === 'model' ? 'actionlog' : 'actionlogs'}`]);

                    //groepen
                    if ($scope.groepen.elementen) {
                        //totalen
                        for (let key of data.normkeys) {
                            velden.push([`T_${key}`, `Aantal elementen van het type ${key}`]);
                        }
                    }
                    if ($scope.groepen.normtelling) {
                        //3 velden ertussen
                        velden.push(
                            ['Good', 'Totaal aantal goede elementen'],
                            ['Wrong', 'Totaal aantal foute elementen'],
                            ['Missing', 'Totaal aantal missende elementen']
                        );
                    }
                    if ($scope.groepen.normtelling_details) {
                        //en nu per type good, wrong, missing
                        for (let key of data.normkeys) {
                            velden.push(
                                [`G_${key}`, `Aantal goede elementen van type ${key}`],
                                [`W_${key}`, `Aantal foute elementen van type ${key}`],
                                [`M_${key}`, `Aantal missende elementen van type ${key}`]
                            );
                        }
                    }

                    if ($scope.groepen.acties || $scope.groepen.acties_details) {
                        //nu de te tellen acties
                        for (let ta of telacties) {
                            if ($scope.groepen.acties) {
                                //totaal
                                velden.push([ta[0], `Totaal aantal ${ta[0]}-acties`]);
                            }
                            if ($scope.groepen.acties_details && ta[1]) {
                                //details
                                for (let key of data.normkeys) {
                                    velden.push([`${ta[0]}_${key}`, `Aantal ${ta[0]} acties m.b.t. elementen van type ${key}`]);
                                }
                            }
                        }
                    }

                    if ($scope.groepen.undoredo) {
                        velden.push(['undo', 'Totaal aantal undo-acties'], ['redo', 'Totaal aantal redo-acties']);
                    }

                    if ($scope.groepen.uniekefouten) {
                        velden.push(['F', `Totaal aantal unieke fouten tijdens bouwproces`]);
                    }

                    if ($scope.groepen.uniekefouten_details) {
                        for (let key of data.normkeys) {
                            velden.push([`F_${key}`, `Totaal aantal unieke fouten m.b.t. geplaatst ${key}-element tijdens bouwproces`]);
                        }
                    }

                    if ($scope.groepen.vraagteken) {
                        velden.push(['?', 'Totaal aantal kliks op vraagteken']);
                    }
                    if ($scope.groepen.vraagteken_details) {
                        for (let key of data.normkeys) {
                            velden.push([`?_${key}`, `Totaal aantal kliks op fout m.b.t. elementen van type ${key}`]);
                        }
                    }
                    if ($scope.groepen.sim) {
                        velden.push(
                            ['sim_full', `Totaal aantal runs volle simulatie`],
                            ['sim_step', `Totaal aantal runs eerste stap simulatie`],
                            ['sim_steps', `Totaal aantal keer volgende stap in simulatie`]);
                    }
                    if ($scope.groepen.sim_details) {
                        velden.push(['sim_selectstate', `Totaal aantal selecteeracties een of meer states`],
                            ['sim_stateselected', 'Totaal aantal geselecteerde states'],
                            ['sim_his_ineq_toggle', 'Totaal aantal keer klikken op ongelijkheidsgeschiedenis aan/uit'], ['sim_his_val_toggle', 'Totaal aantal kleer klikken op waardegeschiedenis aan/uit']);
                    }
                    if ($scope.groepen.feedbackroutes) {
                        velden.push(['FB', 'Totaal aantal unieke element-routes met feedback in simulatie ']);
                    }
                    if ($scope.groepen.uitroepteken) {
                        velden.push(['!', 'Totaal aantal kliks op uitroepteken']);
                    }
                    if ($scope.groepen.uitroepteken_details) {
                        for (let key of data.fbtypes) {
                            velden.push([`FB_${key}`, `Totaal aantal unieke element-routes met feedback van type ${key}`]);
                            velden.push([`!_${key}`, `Totaal aantal kliks op feedback van type ${key}`]);
                        }
                    }
                    if ($scope.groepen.videoclips) {
                        for (let v of data.videos) {
                            velden.push([`V_${v}`, `Aantal vertoningen video ${v}`]);
                        }
                    }
                    //duraties volgen later

                    //uitschrijven velden
                    let col = 1;
                    let rij = 1;

                    for (let v of velden) {
                        sheet.cell(rij, col).value(v[0]);
                        if (v[1]) {
                            sheet.cell(rij + 1, col).value(v[1]);
                        }
                        col++;
                    }
                    //hier achteraan nog de sessietijden
                    sessiecol = col; //onthouden dus
                    sheet.row(rij).style(
                        {
                            bold: true,
                            verticalAlignment: 'center'
                        }
                    );
                    sheet.row(rij + 1).style(
                        {
                            italic: true
                        }
                    )

//////////////////////////////// DATA //////////////////////////////
                    rij++; //skip de uitlegrij
                    for (let r of data.records) {
                        rij++;
                        col = 1;

                        sheet.cell(rij, col++).value(r.domeinnaam)
                        //nu weer de juiste zaken
                        switch (groepeer) {
                            case 'domein':
                                sheet.cell(rij, col++).value(r.projecten);
                                sheet.cell(rij, col++).value(r.users);
                                sheet.cell(rij, col++).value(r.modellen);
                                break;
                            case 'project':
                                sheet.cell(rij, col++).value(r.projectcode);
                                sheet.cell(rij, col++).value(r.users);
                                sheet.cell(rij, col++).value(r.modellen);
                                break;
                            case 'user':
                                sheet.cell(rij, col++).value(r.username);
                                sheet.cell(rij, col++).value(r.iduser);
                                sheet.cell(rij, col++).value(r.projecten);
                                sheet.cell(rij, col++).value(r.modellen);
                                break;
                            case 'model':
                                sheet.cell(rij, col++).value(r.projectcode);
                                sheet.cell(rij, col++).value(r.username);
                                sheet.cell(rij, col++).value(r.iduser);
                                sheet.cell(rij, col++).value(r.titel);
                                sheet.cell(rij, col++).value(r.idmodel);
                                sheet.cell(rij, col++).value(r.samenwerking || '');
                                sheet.cell(rij, col++).value(moment(r.created_at).format('YYYY-MM-DD'));
                                sheet.cell(rij, col++).value(r.wijzigdatum);
                                break;
                        }
                        //numacties
                        sheet.cell(rij, col++).value(r.numacties);

                        //groepen
                        if ($scope.groepen.elementen) {
                            //totalen
                            for (let k of data.normkeys) {
                                sheet.cell(rij, col++).value(r.normstatus[k]?.aantal || 0);
                            }
                        }
                        if ($scope.groepen.normtelling) {
                            sheet.cell(rij, col++).value(r.totaal.goed);
                            sheet.cell(rij, col++).value(r.totaal.fout);
                            sheet.cell(rij, col++).value(r.totaal.missend);
                        }
                        if ($scope.groepen.normtelling_details) {
                            for (let k of data.normkeys) {
                                const t = r.normstatus[k];

                                sheet.cell(rij, col++).value(t?.goed || 0);
                                sheet.cell(rij, col++).value(t?.fout || 0);
                                sheet.cell(rij, col++).value(t?.missend || 0);
                            }
                        }
                        if ($scope.groepen.acties || $scope.groepen.acties_details) {
                            //telacties
                            for (let ta of telacties) {
                                const acties = r.acties[ta[0]] || {};
                                if ($scope.groepen.acties) {
                                    sheet.cell(rij, col++).value(acties._totaal || 0);
                                }
                                if ($scope.groepen.acties_details && ta[1]) {
                                    //per type:

                                    for (let key of data.normkeys) {
                                        //hier moeten we nog even optellen
                                        let som = 0;
                                        for (let sub of Object.keys(acties)) {
                                            if (Elements.isSubtypeOf(sub, key)) {
                                                som += acties[sub];
                                            }
                                        }
                                        sheet.cell(rij, col++).value(som);
                                    }
                                }
                            }
                        }
                        if ($scope.groepen.undoredo) {
                            //die moeten we apart uit de acties halen
                            sheet.cell(rij, col++).value(r.acties.undo?._totaal || 0);
                            sheet.cell(rij, col++).value(r.acties.redo?._totaal || 0);
                        }
                        if ($scope.groepen.uniekefouten) {
                            sheet.cell(rij, col++).value(r.fouten?._totaal || 0);
                        }
                        if ($scope.groepen.uniekefouten_details) {
                            for (let key of data.normkeys) {
                                let som = 0;
                                for (let sub of Object.keys(r.fouten)) {
                                    if (Elements.isSubtypeOf(sub, key)) {
                                        som += r.fouten[sub];
                                    }
                                }
                                sheet.cell(rij, col++).value(som);
                            }
                        }
                        if ($scope.groepen.vraagteken) {
                            //vraagteken
                            //de helpactie wordt gelogd bij "updatefoutniveau" in het fe, als
                            //het foutniveau niet 0 is. Dus openklikken van help, of wellicht daarbinnen een ander niveau
                            //maar dat wordt niet gebruikt
                            sheet.cell(rij, col++).value(r.acties['help']?._totaal || 0);
                        }
                        if ($scope.groepen.vraagteken_details) {
                            for (let key of data.normkeys) {
                                let som = 0;
                                for (let sub of Object.keys(r.foutkliks)) {
                                    if (Elements.isSubtypeOf(sub, key)) {
                                        som += r.foutkliks[sub];
                                    }
                                }
                                sheet.cell(rij, col++).value(som);
                            }
                        }
                        if ($scope.groepen.sim) {
                            sheet.cell(rij, col++).value(r.sim.vol || 0);
                            sheet.cell(rij, col++).value(r.sim.stap || 0);
                            sheet.cell(rij, col++).value(r.sim.stappen || 0);
                        }
                        if ($scope.groepen.sim_details) {
                            sheet.cell(rij, col++).value(r.sim.stateselect || 0);
                            sheet.cell(rij, col++).value(r.sim.statesselected || 0);
                            sheet.cell(rij, col++).value(r.sim.toggle_ih || 0);
                            sheet.cell(rij, col++).value(r.sim.toggle_vh || 0);
                        }
                        if ($scope.groepen.feedbackroutes) {
                            sheet.cell(rij, col++).value(r.sim.feedback_per_type._totaal || 0);
                        }
                        if ($scope.groepen.uitroepteken) {
                            sheet.cell(rij, col++).value(r.sim.fb_show || 0);
                        }
                        if ($scope.groepen.uitroepteken_details) {
                            //en per fbtype
                            for (let key of data.fbtypes) {
                                sheet.cell(rij, col++).value(r.sim.feedback_per_type[key] || 0);
                                sheet.cell(rij, col++).value(r.sim.fbtypes[key] || 0);
                            }
                        }
                        if ($scope.groepen.videoclips) {
                            for (let v of data.videos) {
                                sheet.cell(rij, col++).value(r.videos[v] || 0);
                            }
                        }

                        //sessietijden
                        if ($scope.groepen.duraties) {
                            //sessietijden
                            numsessies = Math.max(numsessies, r.sessies.length); //dus max over modellen
                            let eerstesessie = true;
                            for (let s of r.sessies) {
                                if (!eerstesessie) {
                                    sheet.cell(rij, col++).value(s.interval);
                                }
                                sheet.cell(rij, col++).value(s.duur);
                                eerstesessie = false;
                            }
                        }
                    }

                    //sessiekoppen
                    if ($scope.groepen.duraties) {
                        rij = 1;
                        col = sessiecol;
                        for (let snummer = 0; snummer < numsessies; snummer++) {
                            if (snummer > 0) {
                                sheet.cell(rij, col).value(`Int_${snummer + 1}`);
                                sheet.cell(rij + 1, col++).value(`Pauze voor sessie ${snummer + 1} (seconden)`);
                            }
                            sheet.cell(rij, col).value(`Dur-${snummer + 1}`);
                            sheet.cell(rij + 1, col++).value(`Duur van sessie ${snummer + 1} (seconden)`);
                        }
                    }
                } //einde datadashboard sheet
                //actionlog?
                if (data.actionlogs?.length) {
                    //er is actionlog meegekomen (verzocht en gekregen)
                    let sheet = wb.addSheet('Actionlog');

                    //velden: domein, project, gebruiker id, gebruiker naam, model id, model naam, moment, action, target, targettype, arguments
                    //eerst maar eens de basis
                    let col = 1;
                    let rij = 1;
                    sheet.cell(rij, col++).value('Domein');
                    sheet.cell(rij, col++).value('Project');
                    sheet.cell(rij, col++).value('Leerling Id');
                    sheet.cell(rij, col++).value('Leerling Login');
                    sheet.cell(rij, col++).value('Leerling naam');
                    sheet.cell(rij, col++).value('Model Id');
                    sheet.cell(rij, col++).value('Model naam');
                    sheet.cell(rij, col++).value('Moment');
                    sheet.cell(rij, col++).value('Action User ID');
                    sheet.cell(rij, col++).value('Action User Login');
                    sheet.cell(rij, col++).value('Action');
                    sheet.cell(rij, col++).value('Target');
                    sheet.cell(rij, col++).value('Target type');
                    sheet.cell(rij, col++).value('Arguments');
                    sheet.row(rij).style({
                        bold: true,
                        verticalAlignment: 'center'
                    });
                    rij++;
                    let lastmodel = null;
                    let lastuser = null;
                    for (let al of data.actionlogs) {
                        for (let l of al.actionlog) { //veel herhaling
                            col = 1;
                            sheet.cell(rij, col++).value(al.domeinnaam);
                            sheet.cell(rij, col++).value(al.projectcode);
                            sheet.cell(rij, col++).value(al.user);
                            sheet.cell(rij, col++).value(al.username);
                            sheet.cell(rij, col++).value(al.displaynaam);
                            sheet.cell(rij, col++).value(al.idmodel);
                            sheet.cell(rij, col++).value(al.titel);
                            sheet.cell(rij, col++).value(moment(l.moment).format("YYYY-MM-DD HH:mm:ss"));
                            sheet.cell(rij, col++).value(l.user_id);
                            sheet.cell(rij, col++).value(l.username);
                            sheet.cell(rij, col++).value(l.action);
                            sheet.cell(rij, col++).value(l.target);
                            sheet.cell(rij, col++).value(l.targettype);
                            sheet.cell(rij, col++).value(l.arguments);

                            //specials
                            if (lastuser !== al.user) {
                                //nieuwe user = eventjes bold
                                for (col of [3, 4, 5]) {
                                    sheet.cell(rij, col).style({
                                        bold: true
                                    });
                                }
                                lastuser = al.user;
                            }
                            if (lastmodel !== al.idmodel) {
                                for (col of [6, 7]) {
                                    sheet.cell(rij, col).style({
                                        bold: true
                                    });
                                }
                                lastmodel = al.idmodel;
                            }
                            rij++;
                        }
                    }
                }
/////////////////////////// OUTPUT ////////////////////////////
                //standaardsheet weg
                wb.deleteSheet(0);
                //output de excel via de linktruuk
                let blob = await wb.outputAsync();
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = url;
                //titel
                a.download = `Dynalearn DataDashboard per ${groepeer}.xlsx`;
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
                api.asyncreturn(); //zorg voor bijwerken ux
            }
        }
    ]);


