/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 26-10-2016
 * Time: 16:01
 *
 *
 */

angular.module('dl.login', [
    'ui.router',
    'dl.approuter',
    'sihw.sihwlog',
    'dl.api',
    'dl.splash'
])
    .config(['approuterProvider',
        function (approuterProvider) {
            approuterProvider.state('login', {
                url: '/login',
                openbaar: true, //onze eigen openbaar vlag
                templateUrl: 'states/login/login.html',
                controller: 'LoginController'
            })
        }]
    )
    .controller('LoginController',['$scope','$state','approuter','api','sihwlog', function($scope,$state, approuter, api,sihwlog) {
        var log = sihwlog.logLevel('debug');
        /* models */
        var m_login = {
            domein: localStorage && localStorage.admindomein || "",
            username: localStorage && localStorage.adminuser || "",
            password: ""
        };

        $scope.login = m_login; //formmodel

        //zij we hier via de splash? Zo niet, dan gaan we terug
        if (! approuter.isGo())
        {
            approuter.go('splash');
        }

        /**
         * Handel loginform af
         */
        function doLogin() {
            $scope.formfeedback = "LOGIN.BEZIG";
            api.login(m_login.domein,m_login.username, m_login.password).then(
                function(gelukt)
                {
                    if (gelukt) {
                        log.log('Gelukt');
                        if (localStorage)
                        {
                            localStorage.admindomein = m_login.domein;
                            localStorage.adminuser = m_login.username;
                        }
                        approuter.mainScreen();
                    }
                    else {
                        $scope.formfeedback = "LOGIN.MISLUKT";
                    }
                }
            )
        }
        $scope.doLogin = doLogin;

}]);