/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 26-9-2018
 * Time: 14:05
 *
 * sihw-pinch-zoom directive
 * <sihw-pinch-zoom [max-scale="<getal = onbepaald>]">
 *
 * sihwlog context: pinchzoom
 *
 * Gebaseerd op  https://github.com/ktknest/angular-pinch-zoom.git
 *
 * Deze versie doet niet aan swipe. Je moet hem dus in een container met auto-overflow zetten....
 *
 * LET OP: nog niet echt rijp voor productie. Zonder swipe lijkt het niet goed te werken, dus dat moet er weer in
 */

angular.module('sihw.pinchzoom', ['sihw.angular.config', 'sihw.sihwlog'])
    .directive('sihwPinchZoom', ['sihwAngularConfig', 'sihwlog',
        function (sihwAngularConfig, sihwlog) {
            let log = sihwlog.logLevel(sihwAngularConfig.loglevel('pinchzoom'));
            log.debug('sihwPinchZoom directive');

            return {
                restrict: 'A',
                scope: {
                    maxScale: '<?'
                },
                link: function ($scope, el, attr) {

                    let pinching = false;

                    let distance = 0;
                    let initialDistance = 0;
                    let scale = 1;
                    let relativeScale = 1;
                    let initialScale = 1;
                    // position of the upper left corner of the element
                    let positionX = 0;
                    let positionY = 0;

                    let initialPositionX = 0;
                    let initialPositionY = 0;

                    // central origin (mode : 'pinch')
                    let originX = 0;
                    let originY = 0;


                    //basis:
                    el.css({
                        '-webkit-transform-origin': '0 0',
                        'transform-origin': '0 0'
                    });

                    el.on('touchstart', onTouchstart);
                    el.on('touchmove', onTouchmove);
                    el.on('touchend', onTouchend);

                    function onTouchstart(evt) {
                        let touches = getTouches(evt);
                        if (touches.length) {
                            //init de touch
                            //het element kan zelf ook weer scrollen eventueel
                            initialPositionX = positionX;
                            initialPositionY = positionY;
                        }
                    }

                    function onTouchmove(evt) {
                        // log.debug(`pz: move`, getTouches(evt));
                        let touches = getTouches(evt);

                        let maxScale = parseInt($scope.maxScale, 10);
                        if (isNaN(maxScale) || maxScale <= 1) {
                            maxScale = 9999;
                        }

                        if ((!pinching) && touches.length === 2) {
                            pinching = true;

                            initialScale = scale;
                            initialDistance = getDistance(touches);
                            originX = touches[0].clientX -
                                parseInt((touches[0].clientX - touches[1].clientX) / 2, 10) -
                                el[0].offsetLeft - initialPositionX;
                            originY = touches[0].clientY -
                                parseInt((touches[0].clientY - touches[1].clientY) / 2, 10) -
                                el[0].offsetTop - initialPositionY;

                            log.debug(`Scale origin`, originX, originY);
                        }

                        if (pinching) {
                            evt.preventDefault();

                            distance = getDistance(touches);
                            relativeScale = distance / initialDistance;
                            scale = relativeScale * initialScale;

                            if (scale > maxScale) {
                                scale = maxScale;
                                relativeScale = scale / initialScale;
                            }

                            positionX = originX * (1 - relativeScale) + initialPositionX;
                            positionY = originY * (1 - relativeScale) + initialPositionY;

                            if (scale < 1) {
                                scale = 1;
                                positionX = 0;
                                positionY = 0;
                            }

                            log.debug(`pinch distance ${distance} scale ${scale} position x ${positionX} y ${positionY}`);
                            transformElement();
                        }

                    }

                    function onTouchend(evt) {
                        log.debug(`pz: touch end`, getTouches(evt));
                        pinching = false;
                    }

                    /**
                     * Helper: haal de touches op
                     * @param evt
                     * @return {boolean|Touch[]|TouchList}
                     */
                    function getTouches(evt) {
                        return (evt.originalEvent ? evt.originalEvent.touches : evt.touches) || [];
                    }

                    /**
                     * @param {Array} touches
                     * @return {number}
                     */
                    function getDistance(touches) {
                        let d = Math.sqrt(Math.pow(touches[0].clientX - touches[1].clientX, 2) +
                            Math.pow(touches[0].clientY - touches[1].clientY, 2));
                        return parseInt(d, 10);
                    }

                    /**
                     * @param {number} [duration]
                     */
                    function transformElement() {
                        let matrix = `matrix(${scale},0,0,${scale},${positionX},${positionY})`;

                        el.css({
                            '-webkit-transform': matrix + ' translate3d(0,0,0)',
                            transform: matrix
                        });
                    }
                }
            }
        }])
;