/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 23-1-2018
 * Time: 19:30
 *
 *
 */

angular.module('dl.registratie', [
    'dl.constants',
    'ui.bootstrap',
    'sihw.sihwlog',
    'sihw.confirm',
    'dl.approuter'
])
    .config(['approuterProvider', function (approuterProvider) {
        approuterProvider.state('registratie',
            {
                url: '/registratie',
                openbaar: true, //onze eigen openbaar vlag
                templateUrl: 'states/registratie/registratie.html',
                controller: 'dl.registratie.RegistratieController'
            })
    }])
    .controller('dl.registratie.RegistratieController', ['$scope', '$translate', 'approuter', 'sihwlog', 'sihwconfirm', 'api', "__",
        function ($scope, $translate, approuter, sihwlog, sihwconfirm, api,__ ){
        let log = sihwlog.logLevel('debug');
        log.log('dl.registratie.RegistratieController');
        approuter.menutitel("REGISTREER.HEADER");

        $scope.form = {
            persoonlijk: {},
            org: {}
        };

        //land en taal, we doen een paar gokken
        let taal = $translate.preferredLanguage(); //is er ook als translate nog aan het laden is
        let taalnaarland = {
            nl: 'NL'
        };
        if (taalnaarland[taal])
        {
            $scope.form.org.land = taalnaarland[taal];
        }



        $scope.doeRegistratie = function() {
            api.send("registratie", "registreer", {
                type: 'domein',
                taal: taal, //taalcode
                email: $scope.form.persoonlijk.email, //apart meesturen
                persoonlijk: $scope.form.persoonlijk,
                organisatie: $scope.form.org
            }).then(() => {
                sihwconfirm(__("REGISTREER.OK.TITEL"),__("REGISTREER.OK.TEKST"),__("BTN_CONFIRM"),false).then(() => {
                    approuter.go("login");
                });
            }).catch(err => {
                sihwconfirm(__("REGISTREER.FOUT.TITEL"), __(`REGISTREER.FOUT.${err.foutcode || "INTERN"}`),__("BTN_CONFIRM"),false); //en niet door naar login
            });
        };

    }]);