/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 19-9-2018
 * Time: 15:19
 *
 * Eenvoudige flag-provider. In config (of in de service) kan een flag worden gezet. Deze kan worden gecheckt in de service
 * als de service ergens included is,
 *
 * sihwFlagProvider.set('flagnaam') gechained
 * sihwFlag.set('flagnaam') gechained
 * sihwFlag.unset('flagnaam') gechained
 * sihwFlag.is('flagnaam') : boolean
 * $scope.flags.flagnaam : boolean
 */

angular.module('sihw.flags', [])
    .provider('sihwFlags', function () {
        let flags = {};
        let configflags = [];
        return {
            set(flagnaam) {
              configflags.push(flagnaam);
                return this;
            },

            $get: ['$rootScope', function ($rootScope) {
                let service =  {
                    set(flagnaam) {
                        flags[flagnaam] = true;
                        $rootScope.flags = $rootScope.flags || {};
                        $rootScope.flags[flagnaam] = true;
                    },
                    unset(flagnaam) {
                        delete flags[flagnaam];
                        $rootScope.flags = $rootScope.flags || {};
                        delete $rootScope.flags[flagnaam];
                    },
                    is(flagnaam) {
                        return flags[flagnaam];
                    }
                };
                //init
                //welke hebben we al uit de config?
                for(let flag of configflags)
                {
                    service.set(flag);
                }
                return service;
            }]
        }
    })
    .run(['sihwFlags',function(sihwFlags) {
        //initialiseer de service, zodat eventuele config-flags in de rootScope terugkomen
    }]);