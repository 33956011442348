/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 4-9-2018
 * Time: 16:34
 *
 * simpele checkbox-replacement
 */

angular.module('sihw.switch',['sihw.angular.config', 'sihw.sihwlog'])
    .directive("sihwswitch",['sihwAngularConfig','sihwlog',
        function(sihwAngularConfig, sihwlog) {
            let log = sihwlog.logLevel(sihwAngularConfig.loglevel('controls'));
            log.debug('sihw.controls: sihwswitch');

            return {
                require: '?ngModel',
                restrict: 'E',
                scope: {
                },
                template: `<div class="sihwswitchbar" ng-class="{switched: value}"><div class="sihwswitchdrop"></div></div>`,
                link: function($scope,elm,attr,ngModel) {
                    elm = $(elm);
                    if(! ngModel)
                    {
                        //dummy
                        ngModel = {
                            $viewValue: NaN,
                            $setViewValue: function(val) { this.$viewValue = val;},
                            $render: function() {} //dummy: wordt hieronder wel gezet
                        }
                    }

                    //op welke elemementen doen we het klik-event?
                    //als we IN een label zitten, dan alleen dat label (anders dubbel)
                    //anders onszelf en het label (als dat er is
                    let relevant = elm.closest('label');
                    if (! relevant.length)
                    {
                        //we zitten niet in een label
                        relevant.add(elm);
                        //en eventueel een label met een .for
                        if (elm.attr('id'))
                        {
                            relevant = relevant.add($(`label[for="${elm.attr('id')}"]`));
                        }
                    }

                    //zet klik events op het element en label
                    //we doen klik en geen touch, zodat webviews zelf rekening houden met drag, werkt beter
                    relevant.on('click', doeklik);
                    $scope.$on('$destroy',function() {
                        log.debug('Destroy switch');
                        relevant.off('click', doeklik);
                    });

                    function doeklik(e) {
                        log.debug('Doe klik');
                        if (elm.is('[disabled]')) {
                            return;
                        }
                        ngModel.$setTouched();
                        ngModel.$setViewValue($scope.value = (! ngModel.$viewValue));
                        $scope.$apply();
                    }

                    ngModel.$render = function() {
                        $scope.value = !! ngModel.$viewValue;
                    }
                }
            }
        }]);