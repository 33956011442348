/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 8-11-2016
 * Time: 11:52
 *
 * Domein state: Bewerk een domein
 */

angular.module('dl.domein', [
    'ui.bootstrap',
    'sihw.sihwlog',
    'dl.approuter',
    'dl.api',
    'sihw.alert.service',
    'sihw.datatable',
    'sihw.confirm',
    'dl.constants'
])
    .config(['approuterProvider', function (approuterProvider) {
        approuterProvider.state('domein',
            {
                url: '/domein/:domein',
                templateUrl: 'states/domein/domein.html',
                controller: 'DomeinController'
            })
        //en ook toevoegen hier:
            .state('nieuwdomein', {
                url: '/nieuwdomein',
                templateUrl: 'states/domein/nieuwdomein.html',
                controller: 'NieuwdomeinController'
            })
    }])
    .controller('DomeinController', ['$scope', '$stateParams', '$timeout', '$uibModal', 'approuter', 'sihwlog', 'api', '__', 'sihwconfirm',
        function ($scope, $stateParams, $timeout, $uibModal, approuter, sihwlog, api, __, sihwconfirm) {
            let log = sihwlog.logLevel('debug');
            log.log('DomeinController');

            //we hebben het domein al in de basisdata. Die kopiëren we wel even naar onze eigen versie
            //zodat we geen gedoe hebben met mislukte edits
            var domein = $stateParams.domein || $scope.basis.userdata.userdomein;
            if (!domein) {
                approuter.logout();
                return;
            }

            $scope.domeinadmins = []; //gebruikers met admin-toegang

            //zijn we domeinadmin van dit domein?
            $scope.domeinadmin = ($scope.basis.userdata.caps.superadmin || ($scope.basis.userdata.caps.domeinadmin.indexOf(domein) !== -1));

            approuter.menudomein(domein);
            approuter.menutitel("VIEWTYPE.EDITDOMEIN");

            var domeindata;

            $scope.$watch("basis.domeinen.hash", copydomein);
            initlijsten();

            function copydomein() {
                if (!($scope.basis && $scope.basis.domeinen && $scope.basis.domeinen.arr.length && $scope.basis.domeinen.hash)) {
                    return;
                }
                var basisdomein = $scope.basis.domeinen.hash[domein];
                log.debug("** domein", domein, basisdomein);
                if (!basisdomein) {
                    log.warn('Domein bestaat niet meer');
                    approuter.mainScreen();
                    return;
                }
                $scope.domeindata = domeindata = basisdomein ? angular.merge({}, basisdomein) : {};
                approuter.menutitel("VIEWTYPE.EDITDOMEIN", $scope.domeindata.naam);
                //en de projecten
                api.projecten(domein).then(function (projecten) {
                    $scope.projecten = Object.keys(projecten).map(pr => projecten[pr]);
                });
                //en de gebruikers, maar alleen als we domeinadmin zijn
                if ($scope.domeinadmin) {
                    api.domeingebruikers(domein).then(function (gebruikers) {
                        log.debug("*** gebruikers", gebruikers);
                        $scope.gebruikers = gebruikers;
                        //haal even de domeinadmins bij elkaar
                        $scope.domeinadmins = $scope.gebruikers.filter(g => g.domeinadmin || g.superadmin);
                        //bepaal de displaynaam
                        angular.forEach($scope.gebruikers, function (u) {
                            u.naam = u.displaynaam || u.username;
                        });
                    });
                }
            }

            copydomein();

            /**
             * Klik op project, ga er heen
             * @param project
             */
            $scope.naarProject = function (project) {
                approuter.go('project', {
                    domein: domein,
                    project: project.id
                });
            };

            /**
             * Klik op gebruiker, ga er heen
             * @param gebruiker
             */
            $scope.naarGebruiker = function (gebruiker) {
                approuter.go('gebruiker', {
                    gebruiker: gebruiker.iduser
                });
            };


            $scope.wijzigdomein = function () {
                log.debug($scope.appdata.landen);
                //toegang al geregeld
                $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    windowClass: 'domein_wijzigdomein',
                    scope: $scope, //scope wordt een kind van de onze
                    templateUrl: '/states/domein/wijzigdomein.html',
                    controller: [
                        '$scope', '$uibModalInstance', 'sihwalert',
                        function ($scope, $uibModalInstance, sihwalert) {
                            $scope.model = angular.copy($scope.domeindata); //in $scope.domeindata dus het origineel
                            log.debug($scope.model);
                            $scope.dialog = $uibModalInstance;

                            $scope.opslaan = function () {
                                api.saveDomeindata(domein, $scope.model)
                                    .then(function () {
                                        copydomein();
                                        log.debug('nu close');
                                        $uibModalInstance.close();
                                    }).catch(function (err) {
                                        log.warn(err);
                                    sihwconfirm(__("DOMEIN.WIJZIG.ERROR.TITEL"), err.code ? __("DOMEIN.WIJZIG.ERROR." + err.code) : err.msg, __("BTN_CONFIRM"), false);
                                });
                            };

                        }]
                }).result.then(function () {
                })
                    .catch(function () { //noop
                    });
            };

            $scope.verwijderDomein = function () {
                if (!$scope.basis.userdata.caps.superadmin) {
                    return; //never mind
                }
                sihwconfirm(__("DOMEIN.VERWIJDER.CONFIRM"), __("DOMEIN.VERWIJDER.TEKST"), __('BTN_JA'), __('BTN_NEE')).then(ok => {
                    if (ok) {
                        api.verwijderDomein(domein).then(() => {
                            approuter.go('splash');
                        }).catch(err => {
                            sihwconfirm(__("DOMEIN.VERWIJDER.ERROR.TITEL"), err.code ? __("DOMEIN.VERWIJDER.ERROR." + err.code) : err.msg, __("BTN_CONFIRM"), false);
                        })
                    }
                });
            };

            $scope.nieuwproject = function () {
                if (!($scope.basis.userdata.caps.superadmin || ($scope.basis.userdata.caps.domeinadmin.indexOf(domein) !== -1))) {
                    return; //kan niet
                }
                $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    windowClass: 'domein_addproject',
                    scope: $scope, //scope wordt een kind van de onze, handig voor projectinfo
                    templateUrl: '/states/domein/projecttoevoegen.html',
                    controller: ['$scope', '$uibModalInstance', '$translate', 'sihwalert',
                        function ($scope, $uibModalInstance, $translate, sihwalalert) {
                            $scope.model = {};
                            $scope.annuleren = function () {
                                $uibModalInstance.dismiss();
                            };

                            $scope.opslaan = function () {
                                api.nieuwproject(domein, $scope.model.naam, $scope.model.beschrijving).then(
                                    function (projectid) {
                                        $uibModalInstance.close();
                                        approuter.go('project', {
                                            project: projectid,
                                            nieuw: true
                                        }); //flag dat wijzigproject open moet gaan
                                    }
                                ).catch(function (err) {
                                    $scope.error = err.code ? $translate.instant("DOMEIN.NIEUWPROJECT.ERROR." + err.code) : err.msg;
                                })
                            }
                        }]
                }).result.catch(() => {
                });
            };

            $scope.nieuwegebruiker = function () {
                if (!($scope.basis.userdata.caps.superadmin || ($scope.basis.userdata.caps.domeinadmin.indexOf(domein) !== -1))) {
                    return; //kan niet
                }

                $uibModal.open({
                    animation: true,
                    backdrop: 'static',
                    windowClass: 'domein_adduser',
                    scope: $scope, //scope wordt een kind van de onze, handig voor info
                    templateUrl: '/states/domein/gebruikertoevoegen.html',
                    // size: 'lg',
                    controller: [
                        '$scope', '$uibModalInstance', '$translate', 'sihwconfirm',
                        function ($scope, dlg, $translate, sihwconfirm) {
                            $scope.model = {
                                actief: true,
                                admin: false,
                                superadmin: false,
                                materiaal_toegang: '-',
                            };

                            $scope.options = {};
                            //bepaal de opties voor resetwachtwoord etc
                            var allkeys = $translate.getAvailableLanguageKeys();
                            var currentlang = $scope.model.stuurgegevens = $translate.preferredLanguage(); //verplicht, dus dit is de keuze
                            $scope.emailtaal = currentlang;

                            $scope.options.stuurgegevens = [
                                {
                                    label: $translate.instant('DOMEIN.ADDUSER.STUURGEGEVENS.MAIL', {lang: currentlang.toUpperCase()}),
                                    lang: currentlang
                                }
                            ];
                            allkeys.forEach(function (langkey) {
                                if (langkey === currentlang) {
                                    return; //door maar weer
                                }
                                $scope.options.stuurgegevens.push({
                                    label: $translate.instant('DOMEIN.ADDUSER.STUURGEGEVENS.MAIL', {lang: langkey.toUpperCase()}),
                                    lang: langkey
                                });
                            });

                            /**
                             * Sla de nieuwe gebruiker op
                             */
                            $scope.opslaan = function () {
                                api.nieuweDomeingebruiker(domein, $scope.model, $scope.emailtaal)
                                    .then(() => {
                                        copydomein();
                                        dlg.close();
                                    }).catch(function (err) {
                                        log.warn(err);
                                    sihwconfirm(__("DOMEIN.ADDUSER.ERROR.TITEL"), err.code ? __("DOMEIN.ADDUSER.ERROR." + err.code) : err.msg, __("BTN_CONFIRM"), false);
                                });
                            }
                        }]
                }).result.catch(() => {
                });
            };

            //zet de defs voor de datalijsten
            function initlijsten() {
                $scope.gebruikerslijst = {
                    track: 'iduser',
                    filter: false,
                    standaardssortering: {
                        veld: 'naam',
                        richting: 1,
                    },
                    // filter: false,
                    velden: {
                        naam: {
                            label: __('GEBRUIKER.NAAM'),
                            html: true,
                            format: function (naam, gebruiker) {
                                let icon = "";
                                let docenttag = "";
                                let title = "";
                                if (gebruiker.superadmin) {
                                    icon = "glyphicon-star";
                                    title = __('ACL.SUPERADMIN');
                                }
                                else if (gebruiker.domeinadmin) {
                                    icon = "glyphicon-home";
                                    title = __('ACL.DOMEINADMIN');
                                }
                                else if (gebruiker.projectadmin) {
                                    icon = "glyphicon-folder-open";
                                    title = __('ACL.PROJECTADMIN');
                                }
                                if (gebruiker.docent)
                                {
                                    docenttag = ` <span class="acltag glyphicon glyphicon-blackboard" title="${__('GEBRUIKER.DOCENT')}"></span>`;
                                }

                                return `${naam} <span class="acltag glyphicon ${icon}" title="${title}"></span>${docenttag}`;
                            }
                        },
                        laatstGezien: {
                            label: __('GEBRUIKER.LAATSTGEZIEN'),
                            type: ['moment', {format: __('FORMAT_DATUMTIJD')}]
                        },
                        projecten: {
                            label: __('PROJECTEN'),
                            type: 'int'
                        },
                        modellen: {
                            label: __('MODELLEN'),
                            type: 'int'
                        }
                    }
                };

                $scope.projectenlijst = {
                    track: 'id',
                    filter: false,
                    standaardssortering: {
                        veld: 'code',
                        richting: 1,
                    },
                    velden: {
                        code: {
                            label: __('PROJECT.NAAM')
                        },
                        beschrijving: {
                            label: __('BESCHRIJVING')
                        },
                        actief: {
                            label: __('CONCEPT.ACTIEF'),
                            type: 'bool'
                        },
                        modellen: {
                            label: __('MODELLEN'),
                            type: 'int'
                        }
                    }
                };
            }
        }])
    .controller('NieuwdomeinController', ['$scope', '$translate', 'approuter', 'sihwlog', 'api', 'EMAIL_REGEX', function ($scope, $translate, approuter, sihwlog, api, EMAIL_REGEX) {
        var log = sihwlog.logLevel('debug');
        log.log('NieuwdomeinController');

        //goede toestemming?
        if (!$scope.basis.userdata.caps.superadmin) {
            approuter.mainScreen();
            return;
        }
        $scope.options = {};
        var model = $scope.model = {
            land: ($translate.preferredLanguage() || 'NL').toUpperCase(),
            stuurgegevens: '-'
        };

        log.debug(`model`, model);
        //bepaal de opties voor welkommail
        var allkeys = $translate.getAvailableLanguageKeys();
        var currentlang = $translate.preferredLanguage();

        $scope.options.stuurgegevens = [
            {
                label: $translate.instant('DOMEIN.STUURGEGEVENS.GEENACTIE'),
                lang: '-'
            },
            {
                label: $translate.instant('DOMEIN.STUURGEGEVENS.MAIL', {lang: currentlang.toUpperCase()}),
                lang: currentlang
            }
        ];
        allkeys.forEach(function (langkey) {
            if (langkey === currentlang) {
                return; //door maar weer
            }
            $scope.options.stuurgegevens.push({
                label: $translate.instant('DOMEIN.STUURGEGEVENS.MAIL', {lang: langkey.toUpperCase()}),
                lang: langkey
            });
        });

        $scope.annuleren = function () {
            approuter.mainScreen();
        };


        $scope.opslaan = function () {
            $scope.error = false;
            if (!(model.email && EMAIL_REGEX.test(model.email))) {
                $scope.error = $translate.instant("DOMEIN.ERROR.INVALIDEMAIL");
                return;
            }
            var verzenden = angular.copy(model);
            if (verzenden.stuurgegevens === '-') {
                verzenden.stuurgegevens = false;
            }
            api.nieuwDomein(verzenden).then(function (domeinid) {
                //domeinid is het nieuwe domein
                log.debug('NIEUW DOMEIN:', domeinid);
                approuter.go('domein', {domein: domeinid});
            })
                .catch(function (err) {
                    $scope.error = err.code ? $translate.instant("DOMEIN.ERROR." + err.code) : err.msg;
                });
        }

    }]);
